import imageProfile from "../../assets/img/img-profile.jpg";

export function UserAdmin({ user }) {
  const imagePic = user.user_pic_url ? user.user_pic_url : imageProfile;

  return (
    <tr key={user.id}>
      <td>
        <img
          alt="..."
          src={imagePic}
          className="avatar avatar-sm rounded-circle me-2"
        ></img>{" "}
        <a className="text-heading text-primary-hover font-semibold" href="#">
          {user.name} {user.lastname}
        </a>
      </td>
      <td>{user.email}</td>
      <td>
        {user.role === "superadmin" && (
          <span className="badge text-uppercase bg-soft-primary text-primary rounded-pill">
            Admin
          </span>
        )}

        {user.role === "admin" && (
          <span className="badge text-uppercase bg-soft-primary text-primary rounded-pill">
            RRHH
          </span>
        )}
      </td>
    </tr>
  );
}
