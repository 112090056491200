import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useListCards } from "../../context/listCardsContext";
import { getWithAuth, postWithAuth } from "../../api/api";
import { apiUrls } from "../../api/apiConfig";
import { useAuth } from "../../context/authcontext";
import { Loading } from "../../components/loading";

export function GroupModal({ onClose, card }) {
  const { getLoggedSession } = useAuth();
  const userSession = getLoggedSession();
  const ctx = useListCards();
  const [isLoading, setIsLoading] = useState(false);
  const [groups, setGroups] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);

  useEffect(() => {
    const fetchGroups = async () => {
      setIsLoading(true);
      try {
        const response = await getWithAuth(
          apiUrls.corp.groupsList,
          userSession
        );
        if (response.status === "error") {
          ctx.setMessage(response.detail);
          ctx.setAlertType("warning");
          ctx.openAlertHeader();
        } else {
          setGroups(response.results);
          setSelectedGroups(
            card.groups.map((group_id) => ({
              id: group_id,
              name: response.results.find((g) => g.id === group_id)?.name,
            }))
          );
        }
      } catch (error) {
        ctx.setMessage("Ha ocurrido un error conectando con el servidor");
        ctx.setAlertType("danger");
        ctx.openAlertHeader();
      } finally {
        setIsLoading(false);
      }
    };
    fetchGroups();
  }, []);

  const addGroup = (ev) => {
    setSelectedGroups((prev) =>
      prev.includes(ev.target.value)
        ? prev
        : [
            ...prev,
            {
              id: ev.target.value,
              name: ev.target.options[ev.target.selectedIndex].text,
            },
          ]
    );
  };

  const removeGroup = (group_id) =>
    setSelectedGroups((prev) => prev.filter((group) => group.id !== group_id));

  const handleSave = async (ev) => {
    ev.preventDefault();
    const userSession = getLoggedSession();
    try {
      const response = await postWithAuth(
        apiUrls.cards.manageGroups(card.id),
        { ids: selectedGroups.map((group) => group.id) },
        userSession
      );
      if (response.status === "error") {
        ctx.setMessage(response.detail);
        ctx.setAlertType("warning");
        ctx.openAlertHeader();
      } else {
        ctx.changeApiSuccess();
      }
    } catch (error) {
      ctx.setAlertType("danger");
      ctx.setMessage("Ha ocurrido un error inesperado");
      ctx.openAlertHeader(true);
    } finally {
      onClose();
    }
  };

  if (isLoading) return <Loading />;

  return (
    <Modal show={true} onHide={onClose} centered>
      <Modal.Header closeButton onClick={onClose}>
        <Modal.Title>Agregar grupo a una tarjeta</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container-fluid max-w-screen-md vstack gap-6">
          <form>
            <div>
              <div className="mt-5 mb-5">
                <h4>
                  <u>Grupos</u>
                </h4>
              </div>
              <div className="d-flex align-items-center mb-4">
                <label htmlFor="metrics-sort-by" className="form-label mx-5">
                  Nombre:{" "}
                </label>
                <select
                  onChange={addGroup}
                  name="status"
                  id="metrics-sort-by"
                  className="form-select mx-5"
                  aria-label="Default select example"
                >
                  <option value={"all"}>Seleccionar grupo</option>
                  {groups.map((group) => (
                    <option key={group.id} value={group.id}>
                      {group.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="d-flex flex-column align-items-center mb-4">
              {selectedGroups.map((group) => (
                <div
                  key={group.id}
                  className="py-1 container d-flex justify-content-between"
                >
                  <span>{group.name}</span>
                  <button
                    type="button"
                    className="bg-white"
                    onClick={() => removeGroup(group.id)}
                  >
                    <i className="bi bi-trash text-danger"></i>
                  </button>
                </div>
              ))}
            </div>
            <div className="d-flex mb-4 flex-row-reverse">
              <button
                type="button"
                className="btn btn-sm btn-neutral my-2 mx-2"
                onClick={onClose}
              >
                Cancelar
              </button>
              <button
                type="submit"
                onClick={handleSave}
                className="btn btn-sm btn-primary my-2 mx-2"
              >
                Guardar
              </button>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
}
