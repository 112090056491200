import imageProfile from "../../assets/img/img-profile.jpg";
import { useGroups } from "../../context/groupContext";

export function GroupList({ group }) {
  const ctx = useGroups();
  const imagePic = imageProfile;

  return (
    <tr key={group.id}>
      <td>
        <img
          alt="..."
          src={imagePic}
          className="avatar avatar-sm rounded-circle me-2"
        ></img>{" "}
        <a className="text-heading text-primary-hover font-semibold" href="#">
          {group?.name}
        </a>
      </td>
      <td>{group?.description}</td>
      <td>{group?.cards?.length}</td>
      <td className="text-end">
        <a
          href="#"
          className="btn btn-sm btn-square btn-neutral me-1"
          onClick={() => {
            ctx.setSelectedGroup(group);
            ctx.openModal("editGroup");
          }}
        >
          <i className="bi bi-pencil"></i>{" "}
        </a>
        <button
          type="button"
          className="btn btn-sm btn-square btn-neutral text-danger-hover"
          onClick={() => {
            ctx.setSelectedGroup(group);
            ctx.openModal("delete");
          }}
        >
          <i className="bi bi-trash"></i>
        </button>
      </td>
    </tr>
  );
}
