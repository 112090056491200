import React, { createContext, useContext, useState } from 'react';

const CommonContext = createContext();

export const CommonProvider = ({ children }) => {


    //Gestion del contenido de los Alert
    const [messageLogin, setMessageLogin] = useState('Esto un mensaje de prueba');
    const [alertTypeLogin, setAlertTypeLogin] = useState('success');


    //Gestion del Alert del Header
    const [showAlertHeaderLogin, setShowAlertHeaderLogin] = useState(false);
    const openAlertHeaderLogin = () => {
        setShowAlertHeaderLogin(true);
    };
    const closeAlertHeaderLogin = () => {
        setShowAlertHeaderLogin(false);
    };

    const [usernameEmail, setUsernameEmail] = useState('');

    return (
        <CommonContext.Provider value={{
            alertTypeLogin,
            setAlertTypeLogin,
            setMessageLogin,
            messageLogin,
            openAlertHeaderLogin,
            closeAlertHeaderLogin,
            showAlertHeaderLogin,
            usernameEmail,
            setUsernameEmail
        }}>
            {children}
        </CommonContext.Provider>
    );
};

export const useCommon = () => {
    return useContext(CommonContext);
};
