import React, { createContext, useContext, useState } from "react";

const MetricsContext = createContext();

export const MetricsProvider = ({ children }) => {
  const [alert, setAlert] = useState({ message: "", type: "" });
  const [showAlertHeader, setShowAlertHeader] = useState(false);
  const openAlertHeader = ({ message, type }) => {
    setAlert({ message, type });
    setShowAlertHeader(true);
  };
  const closeAlertHeader = () => setShowAlertHeader(false);

  return (
    <MetricsContext.Provider
      value={{
        alert,
        openAlertHeader,
        closeAlertHeader,
        showAlertHeader,
      }}
    >
      {children}
    </MetricsContext.Provider>
  );
};

export const useMetrics = () => useContext(MetricsContext);
