export function Loading() {
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: "100vh" }}
    >
      <div
        className="spinner-grow text-serveo"
        style={{ width: "10rem", height: "10rem" }}
        role="status"
      ></div>
    </div>
  );
}
