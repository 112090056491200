import React, { useContext, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import AutoDismissAlert from "./autoDismiss";
import { deleteWithAuth } from "../api/api";
import { AuthContext } from "../context/authcontext";

const DeleteModal = ({ onClose, title, message1, message2, apiUrl }) => {
  const [showAlertHeader, setShowAlertHeader] = useState(false);
  const { getLoggedSession } = useContext(AuthContext);

  const [message, setMessage] = useState("Esto un mensaje de prueba");
  const [alertType, setAlertType] = useState("success");

  const handleDelete = async () => {
    const userSession = getLoggedSession();
    try {
      const response = await deleteWithAuth(apiUrl, userSession);
      if (response.status === "error") {
        setMessage(response.detail);
        setAlertType("warning");
        setShowAlertHeader(true);
      } else {
        onClose();
      }
    } catch (error) {
      setMessage("Ha ocurrido un error inesperado");
      setAlertType("danger");
      setShowAlertHeader(true);
    }
  };

  return (
    <Modal show={true} onHide={onClose} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      {showAlertHeader && (
        <div className="m-5">
          <AutoDismissAlert
            message={message}
            alertType={alertType}
            onClose={() => setShowAlertHeader(false)}
          />
        </div>
      )}
      <Modal.Body>
        <span>{message1}</span>
        <p>{message2}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={() => {
            handleDelete();
          }}
        >
          Eliminar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteModal;
