import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useOrgs } from "../../context/orgsContext";
import { apiUrls } from "../../api/apiConfig";
import { getWithAuth } from "../../api/api";
import { AuthContext } from "../../context/authcontext";
import { UserAdmin } from "./userAdmin";
import { Paginator } from "../../components/pagination";

export function ListAdminsModal({ org, closeModal }) {
  const { getLoggedSession } = useContext(AuthContext);
  const userSession = getLoggedSession();
  const ctx = useOrgs();
  const [admins, setAdmins] = useState({ count: null, results: [] });
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 12;

  useEffect(() => {
    const fetchCards = async () => {
      try {
        const response = await getWithAuth(
          `${apiUrls.owners.corpUsersList}?org_id=${org.id}&page=${currentPage}&per_page=${pageSize}`,
          userSession
        );
        setAdmins({ ...response });
      } catch (error) {
        ctx.openAlertHeader({
          message: "Ha ocurrido un error conectando con el servidor",
          type: "danger",
        });
      } finally {
        setIsLoading(false);
        ctx.closeAlertHeader();
      }
    };
    fetchCards();
  }, [currentPage]);

  const onPageChange = (page) => setCurrentPage(page);

  return (
    <Modal
      show={true}
      onHide={() => closeModal("listAdmins")}
      size="xl"
      centered
    >
      <Modal.Header closeButton onClick={() => closeModal("listAdmins")}>
        <Modal.Title>Listar admistradores</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center">
            <div
              className="spinner-grow text-serveo"
              style={{ width: "10rem", height: "10rem" }}
              role="status"
            ></div>
          </div>
        ) : (
          <div className="table-responsive">
            <table className="table table-hover table-nowrap">
              <thead className="table-light">
                <tr>
                  <th scope="col">Nombre</th>
                  <th scope="col">Correo electrónico</th>
                  <th scope="col">Acceso</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {admins.results?.map((admin) => (
                  <UserAdmin key={admin.id} user={admin} />
                ))}
              </tbody>
            </table>
          </div>
        )}
        <div className="w-100 mt-4 d-flex justifiy-content-center align-items-center">
          <Paginator
            currentPage={currentPage}
            totalPages={Math.max(1, Math.ceil(admins.count / pageSize))}
            onPageChange={onPageChange}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}
