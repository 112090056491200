import { useState, useEffect } from "react";
import { Chart as ChartJS } from "chart.js/auto";
import { Bar } from "react-chartjs-2";

import { getWithAuth } from "../../api/api";
import { apiUrls } from "../../api/apiConfig";
import { useAuth } from "../../context/authcontext";
import { useMetrics } from "../../context/metricsContext";

export function MostViewed() {
  const ctx = useMetrics();
  const { getLoggedSession } = useAuth();
  const userSession = getLoggedSession();
  const [isLoading, setIsLoading] = useState(true);
  const [mostViewed, setMostViewed] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getWithAuth(apiUrls.metrics.mostViewed, userSession);
        setMostViewed(data.results);
      } catch (error) {
        ctx.openAlertHeader({
          message: "Ha ocurrido un error conectando con el servidor",
          type: "danger",
        });
      }
      setIsLoading(false);
    };
    fetchData();
  }, []);

  return (
    <div className="flex-grow-1">
      <div className="card">
        <div className="card-header border-0">Tarjetas más visitadas</div>
        <div className="card-body">
          <div className="d-flex">
            {isLoading ? (
              <div className="d-flex justify-content-center align-items-center">
                <div
                  className="spinner-grow text-serveo"
                  style={{ width: "10rem", height: "10rem" }}
                  role="status"
                ></div>
              </div>
            ) : (
              <Bar
                data={{
                  labels: mostViewed.map((card) => card.employee_name),
                  datasets: [
                    {
                      label: "visitas",
                      data: mostViewed.map((card) => card.views),
                      backgroundColor: [
                        "rgba(54, 162, 235, 0.6)",
                        "rgba(255, 99, 132, 0.6)",
                        "rgba(255, 206, 86, 0.6)",
                      ],
                      borderColor: [
                        "rgba(54, 162, 235, 0.6)",
                        "rgba(255, 99, 132, 0.6)",
                        "rgba(255, 206, 86, 0.6)",
                      ],
                      borderWidth: 1,
                      borderRadius: 5,
                    },
                  ],
                }}
              ></Bar>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
