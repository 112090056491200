import { useState, useEffect } from "react";
import { Chart as ChartJS } from "chart.js/auto";
import { Doughnut } from "react-chartjs-2";

import { getWithAuth } from "../../api/api";
import { apiUrls } from "../../api/apiConfig";
import { useAuth } from "../../context/authcontext";
import { useMetrics } from "../../context/metricsContext";

export function CountByGroup() {
  const ctx = useMetrics();
  const { getLoggedSession } = useAuth();
  const userSession = getLoggedSession();
  const [isLoading, setIsLoading] = useState(true);
  const [countByGroup, setCountByGroup] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getWithAuth(
          apiUrls.metrics.countByGroup,
          userSession
        );
        setCountByGroup(data.results);
      } catch (error) {
        ctx.openAlertHeader({
          message: "Ha ocurrido un error conectando con el servidor",
          type: "danger",
        });
      }
      setIsLoading(false);
    };
    fetchData();
  }, []);

  return (
    <div className="d-flex">
      <div className="card">
        <div className="card-header border-0">Tarjetas por grupos</div>
        <div className="card-body">
          <div className="d-flex">
            {isLoading ? (
              <div className="d-flex justify-content-center align-items-center">
                <div
                  className="spinner-grow text-serveo"
                  style={{ width: "10rem", height: "10rem" }}
                  role="status"
                ></div>
              </div>
            ) : (
              <Doughnut
                data={{
                  labels: countByGroup.map((item) => item.group_name),
                  datasets: [
                    {
                      label: "número de tarjetas",
                      data: countByGroup.map((item) => item.count),
                      backgroundColor: [
                        "rgba(54, 162, 235, 0.6)",
                        "rgba(255, 99, 132, 0.6)",
                        "rgba(255, 206, 86, 0.6)",
                      ],
                      borderColor: [
                        "rgba(54, 162, 235, 0.6)",
                        "rgba(255, 99, 132, 0.6)",
                        "rgba(255, 206, 86, 0.6)",
                      ],
                      borderWidth: 1,
                      borderRadius: 5,
                    },
                  ],
                }}
              ></Doughnut>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
