import React, { createContext, useContext, useState } from "react";

const OrgsContext = createContext();

export const OrgsProvider = ({ children }) => {
  const [alert, setAlert] = useState({ message: "", type: "" });
  const [showAlertHeader, setShowAlertHeader] = useState(false);
  const openAlertHeader = ({ message, type }) => {
    setAlert({ message, type });
    setShowAlertHeader(true);
  };
  const closeAlertHeader = () => setShowAlertHeader(false);
  const [showModal, setShowModal] = useState({
    listCards: false,
    listGroups: false,
    listAdmins: false,
    newCard: false,
    newSuperadmin: false,
    editOrg: false,
    deleteOrg: false,
  });
  const openModal = (modal) => setShowModal({ ...showModal, [modal]: true });
  const closeModal = (modal) => setShowModal({ ...showModal, [modal]: false });

  const [apiSuccess, setApiSuccess] = useState(false);
  const changeApiSuccess = () => setApiSuccess(!apiSuccess);

  return (
    <OrgsContext.Provider
      value={{
        alert,
        openAlertHeader,
        closeAlertHeader,
        showAlertHeader,
        showModal,
        openModal,
        closeModal,
        apiSuccess,
        changeApiSuccess,
      }}
    >
      {children}
    </OrgsContext.Provider>
  );
};

export const useOrgs = () => useContext(OrgsContext);
