import React, { useContext, useEffect, useRef, useState } from "react";
import imageProfile from "../assets/img/img-profile.jpg";
import { AuthContext } from "../context/authcontext";
import { apiUrls } from "../api/apiConfig";
import { postWithAuth, postWithAuthMultiPart } from "../api/api";
import { useSetting } from "../context/settingContext";
import { useCommon } from "../context/commonContext";

const UserSetting = ({}) => {
  const settingContext = useSetting();
  const { getLoggedSession } = useContext(AuthContext);
  const fileInputRef = useRef(null); // Ref para acceder al elemento de entrada de archivo

  const commonContext = useCommon();

  const imagePic = settingContext.currentUser?.user_pic_url
    ? settingContext.currentUser.user_pic_url
    : imageProfile;

  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileUpload = async (event) => {
    const userSession = getLoggedSession();
    const fileInput = event.target.files[0];
    const formData = new FormData();
    formData.append("admin_pic", fileInput, "admin.png");

    const response = await postWithAuthMultiPart(
      apiUrls.ADMIN_PATCH_PIC,
      formData,
      userSession
    );

    if (response.status === "success") {
      settingContext.setMessage("Foto cambiada correctamente");
      settingContext.setAlertType("success");
      settingContext.openAlertHeader();
      settingContext.launchRefreshUser();
    } else if (response.status === 404) {
      settingContext.setMessage(response.data.detail);
      settingContext.setAlertType("danger");
      settingContext.openAlertHeader();
    } else {
      settingContext.setMessage("Error inesperado");
      settingContext.setAlertType("danger");
      settingContext.openAlertHeader();
    }
  };

  const [passwordMatch, setPasswordMatch] = useState(false);
  const [userAdminData, setUserAdminData] = useState(
    settingContext.currentUser
  );
  const [userPassword, setUserPassword] = useState({
    old_password: "",
    new_password: "",
  });

  const handleInputChangeUser = (event) => {
    const { name, value } = event.target;
    setUserAdminData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    setPasswordMatch(
      userPassword.new_password === userPassword.repeat_password
    );
  }, [userPassword.new_password, userPassword.repeat_password]);

  const handleInputChangePassword = (event) => {
    const { name, value } = event.target;
    setUserPassword((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();
    const userSession = getLoggedSession();
    try {
      const response = await postWithAuth(
        apiUrls.users.profileChangePassword,
        userPassword,
        userSession
      );
      if (response.status === "success") {
        commonContext.setMessageLogin("Contraseña cambiada correctamente");
        commonContext.setAlertTypeLogin("success");
        commonContext.openAlertHeaderLogin();
      } else if (response.status === "error") {
        commonContext.setMessageLogin(response.detail);
        commonContext.setAlertTypeLogin("danger");
        commonContext.openAlertHeaderLogin();
      } else {
        commonContext.setMessageLogin("Ha ocurrrido un error");
        commonContext.setAlertTypeLogin("danger");
        commonContext.openAlertHeaderLogin();
      }
    } catch (error) {
      console.error("Error creating user admin:", error);
      commonContext.setMessageLogin("algo");
      commonContext.setAlertType("danger");
      commonContext.openAlertHeaderLogin();
    }
  };

  const handleEditUser = async (e) => {
    e.preventDefault(); // Previene el comportamiento predeterminado del envío del formulario
    const userSession = getLoggedSession();
    try {
      const response = await postWithAuth(
        apiUrls.users.profilePatch,
        userAdminData,
        userSession
      );

      if (response.status === "success") {
        settingContext.setMessage("Cambios realizados correctamente");
        settingContext.setAlertType("success");
        settingContext.openAlertHeader();
        settingContext.launchRefreshUser();
      } else if (response.status === 404) {
        settingContext.setMessage(response.data.detail);
        settingContext.setAlertType("danger");
        settingContext.openAlertHeader();
      } else {
        settingContext.setMessage("Error inesperado");
        settingContext.setAlertType("danger");
        settingContext.openAlertHeader();
      }
    } catch (error) {
      console.error("Error creating user admin:", error);
      settingContext.setMessage("Ha ocurrido un error inesperado");
      settingContext.setAlertType("danger");
      settingContext.openAlertHeader();
    }
  };

  return (
    <div className="container-fluid max-w-screen-md vstack gap-6">
      <div className="card">
        <div className="card-body">
          <div className="d-flex align-items-center">
            <div>
              <div className="d-flex align-items-center">
                <a href="#" className="avatar avatar-lg text-white">
                  <img alt="..." src={imagePic}></img>
                </a>

                <div className="ms-4">
                  <span className="h4 d-block mb-0">
                    {settingContext.currentUser.name}{" "}
                    {settingContext.currentUser.lastname}
                  </span>{" "}
                  <a href="#" className="text-sm font-semibold text-muted"></a>
                </div>
              </div>
            </div>

            <input
              type="file"
              accept="image/*"
              onChange={handleFileUpload}
              style={{ display: "none" }} // Estilo para ocultar el elemento de entrada de archivo
              ref={fileInputRef} // Ref para acceder al elemento de entrada de archivo
            />
            <div className="ms-auto">
              <button
                type="button"
                className="btn btn-sm btn-neutral"
                onClick={handleUploadButtonClick}
              >
                Subir foto
              </button>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="mb-5">
          <h4>Información de usuario</h4>
        </div>
        <form onSubmit={handleEditUser}>
          <div className="row g-5">
            <div className="col-md-6">
              <div>
                <label className="form-label">Nombre</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  defaultValue={settingContext.currentUser.name}
                  onChange={handleInputChangeUser}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div>
                <label className="form-label">Apellidos</label>
                <input
                  type="text"
                  name="lastname"
                  className="form-control"
                  defaultValue={settingContext.currentUser.lastname}
                  onChange={handleInputChangeUser}
                />
              </div>
            </div>

            <div className="col-md-6">
              <div>
                <label className="form-label" htmlFor="email">
                  Email
                </label>
                <input
                  id="email"
                  type="email"
                  className="form-control"
                  defaultValue={settingContext.currentUser.email}
                  readOnly
                />
              </div>
            </div>

            <div className="col-12 text-end">
              <button type="submit" className="btn btn-sm btn-primary">
                Guardar
              </button>
            </div>
          </div>
        </form>
      </div>
      <hr className="my-6"></hr>

      <div className="row g-6">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body row g-5">
              <div className="col-md-7">
                <h5 className="h4 mb-5">Cambiar contraseña</h5>
                <form onSubmit={handleChangePassword}>
                  <div className="mb-5">
                    <div>
                      <label className="form-label" htmlFor="old_password">
                        Contraseña actual
                      </label>{" "}
                      <input
                        type="password"
                        className="form-control"
                        onChange={handleInputChangePassword}
                        name="old_password"
                      ></input>
                    </div>
                  </div>

                  <div className="mb-5">
                    <div>
                      <label className="form-label" htmlFor="new_password">
                        Nueva contraseña
                      </label>{" "}
                      <input
                        type="password"
                        className="form-control"
                        onChange={handleInputChangePassword}
                        name="new_password"
                      ></input>
                    </div>
                  </div>
                  <div className="mb-5">
                    <div>
                      <label className="form-label" htmlFor="repeat_password">
                        Repite contraseña
                      </label>{" "}
                      <input
                        type="password"
                        className="form-control"
                        onChange={handleInputChangePassword}
                        name="repeat_password"
                      ></input>
                    </div>
                  </div>
                  <div className="text-end">
                    <button
                      disabled={!passwordMatch}
                      className="btn btn-sm btn-primary"
                    >
                      Cambiar
                    </button>
                  </div>
                </form>
              </div>

              <div className="col-md-5">
                <div className="card bg-dark border-0 shadow-none ml-md-4">
                  <div className="card-body">
                    <h5 className="text-white mb-2">
                      Requisitos de la contraseña
                    </h5>
                    <p className="text-sm text-white mb-3">
                      Con el objetivo de crear contraseñas seguras, estas deben
                      tener:
                    </p>
                    <ul className="text-warning font-code pl-4 mb-0">
                      <li className="text-xs">Mínimo 8 caracteres</li>
                      <li className="text-xs">Al menos una letra minúscula</li>
                      <li className="text-xs">Al menos una letra mayúscula</li>
                      <li className="text-xs">
                        No puede ser igual que la contraseña anterior
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserSetting;
