import React, { createContext, useContext, useState } from "react";

const GroupContext = createContext();

export const GroupProvider = ({ children }) => {
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [refreshGroupList, setRefreshGroupList] = useState(false);
  const [alert, setAlert] = useState({ message: "", type: "" });
  const [showAlertHeader, setShowAlertHeader] = useState(false);
  const openAlertHeader = ({ message, type }) => {
    setAlert({ message, type });
    setShowAlertHeader(true);
  };
  const closeAlertHeader = () => setShowAlertHeader(false);

  const [showModal, setShowModal] = useState({
    newGroup: false,
    editGroup: false,
    delete: false,
  });
  const openModal = (modal) => setShowModal({ ...showModal, [modal]: true });
  const closeModal = (modal) => setShowModal({ ...showModal, [modal]: false });

  const launchRefreshGroupList = () => setRefreshGroupList(!refreshGroupList);

  return (
    <GroupContext.Provider
      value={{
        alert,
        openAlertHeader,
        closeAlertHeader,
        showAlertHeader,
        showModal,
        setShowModal,
        openModal,
        closeModal,
        launchRefreshGroupList,
        selectedGroup,
        setSelectedGroup,
      }}
    >
      {children}
    </GroupContext.Provider>
  );
};

export const useGroups = () => useContext(GroupContext);
