import { ADMIN_USER, GENERAL_USER, OWNER_USER } from "./permissions";

const buildingIconAdd = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="currentColor"
    className="bi bi-building-fill-add"
    viewBox="0 0 16 16"
  >
    <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0" />
    <path d="M2 1a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v7.256A4.5 4.5 0 0 0 12.5 8a4.5 4.5 0 0 0-3.59 1.787A.5.5 0 0 0 9 9.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .39-.187A4.5 4.5 0 0 0 8.027 12H6.5a.5.5 0 0 0-.5.5V16H3a1 1 0 0 1-1-1zm2 1.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5m3 0v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5m3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zM4 5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5M7.5 5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm2.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5M4.5 8a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5z" />
  </svg>
);

export const CardRouter = {
  label: "Cards Menu",
  children: [
    {
      name: "Nueva tarjeta",
      icon: "bi bi-plus-circle",
      to: "/new-card",
      allowedRoles: [GENERAL_USER, ADMIN_USER, OWNER_USER],
      active: true,
    },
    {
      name: "Grupos",
      icon: "bi bi-people-fill",
      to: "/groups",
      allowedRoles: [GENERAL_USER, ADMIN_USER],
      active: true,
    },
    {
      name: "Nueva organización",
      icon: buildingIconAdd,
      to: "/new-organization",
      allowedRoles: [OWNER_USER],
      active: true,
    },
    {
      name: "Organizaciones",
      icon: "bi bi-building",
      to: "/organizations",
      allowedRoles: [OWNER_USER],
      active: true,
    },
    {
      name: "Todas las tarjetas",
      icon: "bi bi-person-badge",
      to: "/list-cards",
      allowedRoles: [GENERAL_USER, ADMIN_USER, OWNER_USER],
      active: true,
    },
    {
      name: "Tarjetas inactivas",
      icon: "bi bi-patch-exclamation-fill",
      to: "/deactivated-cards",
      allowedRoles: [GENERAL_USER, ADMIN_USER, OWNER_USER],
      active: true,
    },
    {
      name: "Usuarios administradores",
      icon: "bi bi-shield-lock",
      to: "/admin-users",
      allowedRoles: [ADMIN_USER],
      active: true,
    },
    {
      name: "Métricas",
      icon: "bi bi-graph-up",
      to: "/metrics",
      allowedRoles: [ADMIN_USER],
      active: true,
    },
  ],
};

export const ConfigurationRouter = {
  label: "Configuración",
  children: [
    {
      name: "Perfil",
      icon: "bi bi-gear",
      to: "/profile",
      active: true,
    },
    {
      name: "Logout ",
      icon: "bi bi-box-arrow-right",
      to: "/logout",
      active: true,
    },
  ],
};

export default [CardRouter, ConfigurationRouter];
