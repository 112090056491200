import React, { useContext, useRef, useState } from "react";
import imageProfile from "../assets/img/img-profile.jpg";
import AutoDismissAlert from "./autoDismiss";
import { AuthContext } from "../context/authcontext";
import { apiUrls } from "../api/apiConfig";
import { postWithAuth } from "../api/api";
import { useUser } from "../context/userContext";
import { ADMIN_USER } from "../routes/permissions";

const UserProfile = ({ user, isNewUser, isOwnUser }) => {
  const userContext = useUser();

  const [showAlertHeader, setShowAlertHeader] = useState(false);
  const [showAlertImage] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null); // Estado para almacenar el archivo seleccionado
  const fileInputRef = useRef(null); // Ref para acceder al elemento de entrada de archivo

  const imagePic = user?.user_pic_url ? user.user_pic_url : imageProfile;

  const handleUploadButtonClick = () => {
    // Abrir el cuadro de diálogo de selección de archivo al hacer clic en el botón
    fileInputRef.current.click();
  };
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const [message, setMessage] = useState("Esto un mensaje de prueba");
  const [alertType] = useState("danger");

  const { getLoggedSession } = useContext(AuthContext);

  const [userAdminData, setUserAdminData] = useState(user);

  const handleInputChange = (event) => {
    const { id, value, type, checked } = event.target;
    setUserAdminData((prevData) => ({
      ...prevData,
      [id]: type === "checkbox" ? (checked ? "superadmin" : "admin") : value,
    }));
  };

  const handleNewAdmin = async (e) => {
    e.preventDefault();
    const userSession = getLoggedSession();
    const url = isNewUser
      ? apiUrls.users.adminsPost
      : apiUrls.users.adminsPatch(user.sub);
    try {
      const response = await postWithAuth(url, userAdminData, userSession);
      if (response.status === "success") {
        isNewUser
          ? userContext.closeNewUserModal()
          : userContext.closeEditUserModal();
        userContext.launchRefreshUserList();
      } else {
        throw new Error(
          response.status === "error" ? response.detail : "Error inesperado"
        );
      }
    } catch (error) {
      setMessage(error);
      setShowAlertHeader(true);
    }
  };

  return (
    <div className="container-fluid max-w-screen-md vstack gap-6">
      {showAlertHeader && (
        <div>
          {/* Contenido para cuando isOkSaveConfig o isOkSavePassword son verdaderos */}
          <AutoDismissAlert
            message={message}
            alertType={alertType}
            onClose={() => setShowAlertHeader(false)}
          />
          {/* Más contenido para cuando isOkSaveConfig o isOkSavePassword son verdaderos */}
        </div>
      )}
      {!isNewUser && (
        <div className="card">
          <div className="card-body">
            <div className="d-flex align-items-center">
              <div>
                <div className="d-flex align-items-center">
                  <a href="#" className="avatar avatar-lg text-white">
                    <img alt="..." src={imagePic}></img>
                  </a>

                  <div className="ms-4">
                    <span className="h4 d-block mb-0">
                      {user.name} {user.lastname}
                    </span>{" "}
                    <a
                      href="#"
                      className="text-sm font-semibold text-muted"
                    ></a>
                  </div>
                  {showAlertImage && (
                    <div className="m-4">
                      {/* Contenido para cuando isOkSaveConfig o isOkSavePassword son verdaderos */}
                      <AutoDismissAlert
                        message={message}
                        alertType={alertType}
                        onClose={() => setShowAlertHeader(false)}
                      />
                      {/* Más contenido para cuando isOkSaveConfig o isOkSavePassword son verdaderos */}
                    </div>
                  )}
                </div>
              </div>

              <input
                type="file"
                accept="image/*"
                onChange={handleFileUpload}
                style={{ display: "none" }} // Estilo para ocultar el elemento de entrada de archivo
                ref={fileInputRef} // Ref para acceder al elemento de entrada de archivo
              />
              {isOwnUser && (
                <div className="ms-auto">
                  <button
                    type="button"
                    className="btn btn-sm btn-neutral"
                    onClick={handleUploadButtonClick}
                  >
                    Subir foto
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <div>
        <div className="mb-5">
          <h4>Información de usuario</h4>
        </div>
        <form onSubmit={handleNewAdmin}>
          <div className="row g-5">
            <div className="col-md-6">
              <div>
                <label className="form-label">Nombre</label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  defaultValue={isNewUser ? "" : user.name}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div>
                <label className="form-label">Apellidos</label>
                <input
                  type="text"
                  id="lastname"
                  className="form-control"
                  defaultValue={isNewUser ? "" : user.lastname}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            {!isOwnUser && (
              <div className="col-md-6">
                <div>
                  <label className="form-label" htmlFor="email">
                    Email
                  </label>
                  <input
                    id="email"
                    type="email"
                    className="form-control"
                    defaultValue={isNewUser ? "" : user.email}
                    onChange={handleInputChange}
                    readOnly={isNewUser ? false : true}
                  />
                </div>
              </div>
            )}
            {isNewUser && (
              <div className="col-md-6">
                <div>
                  <label className="form-label" htmlFor="password">
                    Contraseña
                  </label>
                  <input
                    id="password"
                    type="password"
                    className="form-control"
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            )}

            {isNewUser || getLoggedSession().type_user === ADMIN_USER ? (
              <div className="col-12">
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    htmlFor="role"
                    type="checkbox"
                    name="role"
                    id="role"
                    onChange={handleInputChange}
                    checked={userAdminData.role === "superadmin"}
                  />
                  <label className="form-check-label" htmlFor="accept_calls">
                    Usuario administrador
                  </label>
                </div>
              </div>
            ) : null}

            <div className="col-12 text-end">
              <button type="submit" className="btn btn-sm btn-primary">
                Guardar
              </button>
            </div>
          </div>
        </form>
      </div>
      {!isNewUser && <hr className="my-6"></hr>}
    </div>
  );
};

export default UserProfile;
