import React, { useState, useEffect } from "react";

function AutoDismissAlert({ message, alertType, time, onClose }) {
  const [showAlert, setShowAlert] = useState(true);

  useEffect(() => {
    if (time) {
      const timer = setTimeout(() => {
        setShowAlert(false);
      }, time);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [time]);

  const alertClassName = `alert alert-${alertType}`;

  const handleClose = () => {
    setShowAlert(false);
    if (onClose) {
      onClose();
    }
  };

  return showAlert ? (
    <div className={alertClassName} role="alert">
      <div className="d-flex align-items-center mr-3">
        <button
          type="button"
          className="btn-close me-4"
          onClick={() => {
            setShowAlert(false);
            handleClose();
          }}
          aria-label="Close"
        ></button>
        <span className="ml-5">{message}</span>
      </div>
    </div>
  ) : null;
}

export default AutoDismissAlert;
