import React, { useState } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { useOrgs } from "../../context/orgsContext";
import { postWithAuthMultiPart } from "../../api/api";
import { apiUrls } from "../../api/apiConfig";
import { useAuth } from "../../context/authcontext";
import imgOrg from "../../assets/img/background/3.jpg";
import { FileUploader } from "../../new_org/components/fileUploader";

export function EditOrgModal({ org, closeModal }) {
  const { getLoggedSession } = useAuth();
  const userSession = getLoggedSession();
  const ctx = useOrgs();
  const [image, setImage] = useState(org.config.logo_url ?? imgOrg);
  const [logo, setLogo] = useState(null);
  const [patchedOrg, setPatchedOrg] = useState({
    name: org.name,
    main_color: org.config.main_color ?? "",
    secondary_color: org.config.secondary_color ?? "",
    domains: org.domains.join(", ") ?? "",
  });

  const handleInputChange = (ev) => {
    const { id, value } = ev.target;
    setPatchedOrg({ ...patchedOrg, [id]: value });
  };

  const handleSave = async (ev) => {
    ev.preventDefault();
    const orgData = {
      name: patchedOrg.name,
      config: {
        main_color: patchedOrg.main_color,
        secondary_color: patchedOrg.secondary_color,
      },
      domains: patchedOrg.domains.split(",").map((d) => d.trim()),
    };
    const formData = new FormData();
    formData.append("data", JSON.stringify(orgData));
    formData.append("logo", logo, "logo.jpg");
    try {
      await postWithAuthMultiPart(
        apiUrls.owners.orgsPatch(org.id),
        formData,
        userSession
      );
    } catch (error) {
      ctx.openAlertHeader({
        message: "Ha ocurrido un error conectando con el servidor",
        type: "danger",
      });
    } finally {
      closeModal("editOrg");
      ctx.changeApiSuccess();
    }
  };

  return (
    <Modal show={true} size="lg" onHide={() => closeModal("editOrg")} centered>
      <Modal.Header closeButton onClick={() => closeModal("editOrg")}>
        <Modal.Title>Editar organización</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container-fluid max-w-screen-md vstack gap-6">
          <Form onSubmit={handleSave}>
            <Form.Group className="mb-4" controlId="name">
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                value={patchedOrg.name}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group className="mb-4" controlId="main_color">
              <Form.Label>Color principal</Form.Label>
              <Form.Control
                type="text"
                value={patchedOrg.main_color}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group className="mb-4" controlId="secondary_color">
              <Form.Label>Color secundario</Form.Label>
              <Form.Control
                type="text"
                value={patchedOrg.secondary_color}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group className="mb-4" controlId="domains">
              <Form.Label>Dominios aceptados</Form.Label>
              <Form.Control
                type="text"
                value={patchedOrg.domains}
                onChange={handleInputChange}
              />
              <Form.Text className="muted">
                Separar cada dominio con coma
              </Form.Text>
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label>Logo</Form.Label>
              <FileUploader
                image={image}
                setImage={setImage}
                setLogo={setLogo}
              />
            </Form.Group>
            <div className="d-flex mb-4 flex-row-reverse">
              <Button
                className="btn btn-sm btn-neutral my-2 mx-2"
                onClick={() => closeModal("editOrg")}
              >
                Cancelar
              </Button>
              <Button
                type="submit"
                className="btn btn-sm btn-primary my-2 mx-2"
              >
                Guardar
              </Button>
            </div>
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  );
}
