import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useOrgs } from "../../context/orgsContext";
import { apiUrls } from "../../api/apiConfig";
import { getWithAuth } from "../../api/api";
import { AuthContext } from "../../context/authcontext";
import { Group } from "./group";
import { Paginator } from "../../components/pagination";

export function ListGroupsModal({ org, closeModal }) {
  const { getLoggedSession } = useContext(AuthContext);
  const userSession = getLoggedSession();
  const ctx = useOrgs();
  const [groups, setGroups] = useState({ count: null, results: [] });
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 12;

  useEffect(() => {
    const fetchCards = async () => {
      try {
        const response = await getWithAuth(
          `${apiUrls.owners.corpGroupsList}?org_name=${org.name}&page=${currentPage}&per_page=${pageSize}`,
          userSession
        );
        setGroups({ ...response });
      } catch (error) {
        ctx.openAlertHeader({
          message: "Ha ocurrido un error conectando con el servidor",
          type: "danger",
        });
      } finally {
        setIsLoading(false);
        ctx.closeAlertHeader();
      }
    };
    fetchCards();
  }, [currentPage]);

  const onPageChange = (page) => setCurrentPage(page);

  return (
    <Modal
      show={true}
      onHide={() => closeModal("listGroups")}
      size="xl"
      centered
    >
      <Modal.Header closeButton onClick={() => closeModal("listGroups")}>
        <Modal.Title>Listar grupos</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center">
            <div
              className="spinner-grow text-serveo"
              style={{ width: "10rem", height: "10rem" }}
              role="status"
            ></div>
          </div>
        ) : (
          <div className="table-responsive">
            <table className="table table-hover table-nowrap">
              <thead className="table-light">
                <tr>
                  <th scope="col">Nombre</th>
                  <th scope="col">Descripción</th>
                  <th scope="col">Total de tarjetas</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {groups.results?.map((group) => (
                  <Group key={group.id} group={group} />
                ))}
              </tbody>
            </table>
          </div>
        )}
        <div className="w-100 mt-4 d-flex justifiy-content-center align-items-center">
          <Paginator
            currentPage={currentPage}
            totalPages={Math.max(1, Math.ceil(groups.count / pageSize))}
            onPageChange={onPageChange}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}
