// apiConfig.js
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const mainUrls = {
  root: `${API_BASE_URL}/`,
  docs: `${API_BASE_URL}/docs`,
};

const authUrls = {
  confirmRegistry: `${API_BASE_URL}/confirm-registry`,
  login: `${API_BASE_URL}/login`,
  resendCode: `${API_BASE_URL}/resend-confirmation-code`,
  forgotPassword: `${API_BASE_URL}/forgot-password`,
  confirmForgotPassword: `${API_BASE_URL}/reset-password`,
  changePassword: `${API_BASE_URL}/change-password`,
  logout: `${API_BASE_URL}/logout`,
};

const ownerUrls = {
  orgsList: `${API_BASE_URL}/owner/organizations`,
  orgsPost: `${API_BASE_URL}/owner/organizations/new`,
  orgsPatch: (orgId) => `${API_BASE_URL}/owner/organizations/edit/${orgId}`,
  orgsDelete: (orgId) => `${API_BASE_URL}/owner/organizations/delete/${orgId}`,
  newSuperadmin: `${API_BASE_URL}/owner/users/superadmins/new`,
  corpGroupsList: `${API_BASE_URL}/owner/corporate/groups`,
  corpUsersList: `${API_BASE_URL}/owner/corporate/users`,
  cardsList: `${API_BASE_URL}/owner/cards`,
  cardsPost: `${API_BASE_URL}/owner/cards/new`,
  cardsPatch: (cardId) => `${API_BASE_URL}/owner/cards/edit/${cardId}`,
  cardsPatchPic: (cardId) => `${API_BASE_URL}/owner/cards/edit/${cardId}/pic`,
  cardsDelete: (cardId) => `${API_BASE_URL}/owner/cards/delete/${cardId}`,
  usersNewSuperadmin: `${API_BASE_URL}/owner/users/superadmins/new`,
  usersList: `${API_BASE_URL}/owner/users`,
  usersDelete: (usersub) => `${API_BASE_URL}/owner/users/delete/${usersub}`,
};

const corpUrls = {
  // organization
  orgGet: `${API_BASE_URL}/corporate/organization`,
  orgPatch: `${API_BASE_URL}/corporate/organization/edit`,
  // groups
  groupsList: `${API_BASE_URL}/corporate/groups`,
  groupsPost: `${API_BASE_URL}/corporate/groups/new`,
  groupsPatch: (groupId) => `${API_BASE_URL}/corporate/groups/edit/${groupId}`,
  groupsAddCards: (groupId) =>
    `${API_BASE_URL}/corporate/groups/add-cards/${groupId}`,
  groupsDelete: (groupId) =>
    `${API_BASE_URL}/corporate/groups/delete/${groupId}`,
};

const cardUrls = {
  // cards
  list: `${API_BASE_URL}/cards`,
  post: `${API_BASE_URL}/cards/new`,
  patch: (cardId) => `${API_BASE_URL}/cards/edit/${cardId}`,
  patchPic: (cardId) => `${API_BASE_URL}/cards/edit/${cardId}/pic`,
  delete: (cardId) => `${API_BASE_URL}/cards/delete/${cardId}`,
  // corporate
  manageGroups: (cardId) => `${API_BASE_URL}/cards/edit/${cardId}/groups`,
  valPending: `${API_BASE_URL}/validation/pending`,
  valChangeStatus: (cardId) =>
    `${API_BASE_URL}/validation/change-status/${cardId}`,
};

const metricUrls = {
  list: `${API_BASE_URL}/metrics`,
  mostViewed: `${API_BASE_URL}/metrics/cards/most-viewed`,
  countByGroup: `${API_BASE_URL}/metrics/cards/count-by-group`,
};

const userUrls = {
  // admins
  adminsList: `${API_BASE_URL}/corporate/admin`,
  adminsPost: `${API_BASE_URL}/corporate/admin/new`,
  adminsPatch: (usersub) => `${API_BASE_URL}/corporate/admin/edit/${usersub}`,
  adminsDelete: (usersub) =>
    `${API_BASE_URL}/corporate/admin/delete/${usersub}`,
  adminsChangePassword: `${API_BASE_URL}/corporate/admin/change-password`,
  // profile
  profileGet: `${API_BASE_URL}/profile`,
  profilePatch: `${API_BASE_URL}/profile/edit`,
  profileChangePassword: `${API_BASE_URL}/profile/change-password`,
};

export const apiUrls = {
  main: mainUrls,
  auth: authUrls,
  owners: ownerUrls,
  corp: corpUrls,
  cards: cardUrls,
  metrics: metricUrls,
  users: userUrls,
};
