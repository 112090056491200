import React, { useContext, useState, useRef } from "react";
import AutoDismissAlert from "../../components/autoDismiss";
import imageDefault from "../../assets/img/background/2.jpg";
import { apiUrls } from "../../api/apiConfig";
import { postWithAuthMultiPart } from "../../api/api";
import { AuthContext } from "../../context/authcontext";
import { useNavigate } from "react-router-dom";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

const CardContentNew = () => {
  const [showAlertHeader, setShowAlertHeader] = useState(false);
  const [message, setMessage] = useState("Esto es un mensaje de prueba");
  const [alertType, setAlertType] = useState("success");
  const defaultSrc = imageDefault;
  const [image, setImage] = useState(defaultSrc);
  const [fileSend, setFileSend] = useState(null);
  const [cropData, setCropData] = useState(null);
  const [croppedImage, setCroppedImage] = useState(false);
  const navigate = useNavigate();
  const cropperRef = useRef(null);
  const { getLoggedSession } = useContext(AuthContext);
  const fileInputRef = useRef(null); // Ref para el input de tipo archivo
  const IMAGE_SIZE_ALLOWED = process.env.REACT_IMAGE_SIZE_ALLOWED;

  const [newCard, setCard] = useState({
    background_image: 0,
    validation_status: "accepted",
    accept_calls: true,
  });

  const onChange = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };

  const getCropData = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      setCropData(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
      const canvas = cropperRef.current?.cropper.getCroppedCanvas();
      if (canvas) {
        canvas.toBlob((blob) => {
          if (blob) {
            const file = new File([blob], "cropped-image.jpg", {
              type: "image/jpeg",
            });
            setFileSend(file);
          }
        }, "image/jpeg");
      }
      setCroppedImage(true);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    setCard((prevCard) => {
      const updatedCard = {
        ...prevCard,
        [name]: type === "checkbox" ? !prevCard[name] : value,
        background_image: selectedImageIndex,
      };
      return updatedCard;
    });
  };

  const handleSave = async (e) => {
    const userSession = getLoggedSession();
    // Validar el tamaño de la imagen
    if (fileSend && fileSend.size > IMAGE_SIZE_ALLOWED) {
      setAlertType("danger");
      setMessage("La imagen es demasiado grande. Debe ser menor de 3 MB.");
      setShowAlertHeader(true);
      return;
    }

    const formData = new FormData();
    formData.append("card_pic", fileSend, "admin.png");
    formData.append("data", JSON.stringify(newCard)); // Convertir newCard a una cadena JSON

    try {
      const response = await postWithAuthMultiPart(
        userSession.type_user === "owner"
          ? apiUrls.owners.cardsPost
          : apiUrls.cards.post,
        formData,
        userSession
      );

      if (response.status === "success") {
        navigate("/list-cards");
      } else if (response.status === 404) {
        setAlertType("danger");
        setMessage(response.detail);
        setShowAlertHeader(true);
      } else {
        setAlertType("danger");
        setMessage("Error inesperado");
        setShowAlertHeader(true);
      }
    } catch (error) {
      console.error("Error creating card admin:", error);
      setAlertType("danger");
      setMessage("Debes rellenar todos los campos");
      setShowAlertHeader(true);
    }
  };

  const importAll = (r) => {
    let images = {};
    r.keys().forEach((item, index) => {
      images[item.replace("./", "")] = r(item);
    });
    return images;
  };

  const images = importAll(
    require.context("../../assets/img/background/", false, /\.(png|jpg|svg)$/)
  );
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  return (
    <div className="container-fluid max-w-screen-md vstack gap-6">
      <div className="mt-5 mb-5">
        <h4>
          <u>Imagen de perfil</u>
        </h4>
      </div>

      <div>
        <button
          type="button"
          onClick={() => fileInputRef.current.click()}
          className="mb-4 btn btn-sm btn-neutral"
        >
          Subir nueva foto de perfil
        </button>
        <div className="d-flex align-items-center">
          <input
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            ref={fileInputRef} // Ref para el input de tipo archivo
            onChange={onChange}
          />

          <Cropper
            style={{ height: 400, width: "100%" }}
            aspectRatio={9 / 16}
            src={image}
            ref={cropperRef}
            guides={true}
            background={false}
            responsive={true}
            preview=".img-preview"
          />
        </div>
        <button
          type="button"
          className="mt-3 btn btn-sm px-3 btn-primary d-flex align-items-center"
          onClick={getCropData}
        >
          <i className="bi bi-plus-square-dotted me-2"></i>
          <span>Establecer recorte</span>
        </button>
        {cropData ? (
          <>
            <div className="d-flex align-items-center">
              <span className="mt-3">
                Esta imagen aparecerá como imagen de portada de su tarjeta
                corporativa
              </span>
            </div>
            <img
              className="fixed-height-cropper mt-4"
              alt="..."
              src={cropData}
            />
          </>
        ) : (
          <div className="mt-3 alert alert-warning" role="alert">
            <div className="d-flex align-items-center mr-3">
              <span className="ml-5">
                Recorta una imagen compatible con los dispositivos móviles antes
                de continuar
              </span>
            </div>
          </div>
        )}
      </div>

      <form>
        <div className="mt-5 mb-5">
          <h4>
            <u>Datos personales y de contacto</u>
          </h4>
        </div>
        <div>
          <div className="row g-5">
            <div className="col-md-6">
              <div>
                <label className="form-label">Nombre</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div>
                <label className="form-label">Apellidos</label>
                <input
                  type="text"
                  className="form-control"
                  name="lastname"
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div>
                <label className="form-label" htmlFor="email">
                  Email
                </label>
                <input
                  name="email"
                  type="email"
                  onChange={handleInputChange}
                  className="form-control"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div>
                <label className="form-label" htmlFor="phone">
                  Teléfono de contacto (Opcional)
                </label>
                <input
                  type="text"
                  name="phone"
                  onChange={handleInputChange}
                  className="form-control"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div>
                <label className="form-label" htmlFor="phone">
                  Otro teléfono de contacto (Opcional)
                </label>
                <input
                  type="text"
                  name="phone2"
                  onChange={handleInputChange}
                  className="form-control"
                />
              </div>
            </div>
          </div>
          <div className="mb-5 mt-5">
            <h4>
              <u>Datos profesionales</u>
            </h4>
          </div>
          <div className="row g-5">
            <div className="col-md-12">
              <div>
                <label className="form-label">Tu puesto de trabajo</label>
                <input
                  type="text"
                  name="job"
                  onChange={handleInputChange}
                  className="form-control"
                />
              </div>
            </div>

            <div className="col-md-12">
              <div>
                <label className="form-label">Descripción del puesto</label>
                <textarea
                  className="form-control"
                  name="job_description"
                  onChange={handleInputChange}
                  placeholder="Descripción del puesto del trabajador"
                  rows="2"
                ></textarea>
              </div>
            </div>

            <div className="col-md-6">
              <div>
                <label className="form-label" htmlFor="text">
                  Departamento (Opcional)
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="department"
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div>
                <label className="form-label" htmlFor="phone">
                  Dirección del centro de trabajo (Opcional)
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="address"
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
      <hr className="my-6" />
      <div className="row g-6">
        <div className="col-md-6">
          <div className="card">
            <div className="card-body">
              <h4 className="mb-2">Aceptar llamadas</h4>
              <p className="text-sm text-muted mb-6">
                Activando esta opción, el usuario podrá recibir solicitudes de
                llamada
              </p>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="accept_calls"
                  name="accept_calls"
                  onChange={handleInputChange}
                  checked={newCard.accept_calls}
                />
                <label className="form-check-label" htmlFor="accept_calls">
                  Aceptar llamadas
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showAlertHeader && (
        <div>
          <AutoDismissAlert
            message={message}
            alertType={alertType}
            onClose={() => setShowAlertHeader(false)}
          />
        </div>
      )}
      <div className="d-flex mb-4 flex-row-reverse">
        <button type="button" className="btn btn-sm btn-neutral my-2 mx-2">
          Cancelar
        </button>
        <button
          type="submit"
          onClick={handleSave}
          disabled={!croppedImage}
          className="btn btn-sm btn-primary my-2 mx-2"
        >
          Guardar
        </button>

        <div className="col-md-2 col-sm-12 my-2 mx-2">
          <div>
            <select
              onChange={handleInputChange}
              name="validation_status"
              className="form-select"
              aria-label="Default select example"
            >
              <option value="accepted">Verificado</option>
              <option value="pending">Pendiente</option>
              <option value="rejected">Rechazado</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardContentNew;
