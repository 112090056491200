import React, { useEffect, useState, useContext } from "react";
import DeleteModal from "../../components/deleteModal";
import EditUserModal from "../components/editUserModal";
import NewUserModal from "../components/newUserModal";
import { getWithAuth } from "../../api/api";
import { apiUrls } from "../../api/apiConfig";
import AutoDismissAlert from "../../components/autoDismiss";
import { AuthContext } from "../../context/authcontext";
import { useUser } from "../../context/userContext";
import UserList from "../components/userList";
import { Loading } from "../../components/loading";
import { Paginator } from "../../components/pagination";

const ListUsers = () => {
  const { getLoggedSession } = useContext(AuthContext);
  const ctx = useUser();
  const [isLoading, setIsLoading] = useState(true);
  const [admins, setAdmins] = useState({ count: null, results: [] });
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 12;

  useEffect(() => {
    const userSession = getLoggedSession();
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const data = await getWithAuth(
          `${apiUrls.users.adminsList}?page=${currentPage}&per_page=${pageSize}`,
          userSession
        );
        setAdmins(data);
      } catch (error) {
        ctx.setMessage("Ha ocurrido un error conectando con el servidor");
        ctx.setAlertType("danger");
        ctx.openAlertHeader();
        console.error("Error fetching admin users data:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [ctx.refreshUserList, currentPage]);

  const onPageChange = (page) => setCurrentPage(page);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("all");

  const filteredUsers = admins.results?.filter((user) => {
    const fullName = `${user.name} ${user.lastname}`.toLowerCase();
    const userEmail = user.email.toLowerCase();

    // Filtra por categoría
    if (selectedCategory === "all") {
      // Si se selecciona "Ver todos", no aplicamos ningún filtro de categoría
      return (
        fullName.includes(searchTerm.toLowerCase()) ||
        userEmail.includes(searchTerm.toLowerCase())
      );
    } else if (selectedCategory === "RRHH") {
      // Filtrar por usuarios de RRHH
      return (
        user.role === "admin" &&
        (fullName.includes(searchTerm.toLowerCase()) ||
          userEmail.includes(searchTerm.toLowerCase()))
      );
    } else if (selectedCategory === "Admin") {
      // Filtrar por usuarios de Admin
      return (
        user.role === "superadmin" &&
        (fullName.includes(searchTerm.toLowerCase()) ||
          userEmail.includes(searchTerm.toLowerCase()))
      );
    }

    return false; // En caso de categoría no válida
  });

  return (
    <div className="container-fluid ">
      {ctx.showAlertHeader && (
        <div className="">
          {/* Contenido para cuando isOkSaveConfig o isOkSavePassword son verdaderos */}
          <AutoDismissAlert
            message={ctx.message}
            alertType={ctx.alertType}
            onClose={ctx.closeAlertHeader}
          />
          {/* Más contenido para cuando isOkSaveConfig o isOkSavePassword son verdaderos */}
        </div>
      )}
      {isLoading ? (
        // Mostrar el indicador de carga mientras se carga la API.
        <Loading />
      ) : (
        <div className="vstack gap-4 mt-4">
          <div className="card">
            <div className="card-header d-flex align-items-center">
              <div className="scrollable-x">
                <div className="btn-group">
                  <a
                    href="#"
                    className={`btn btn-sm btn-neutral text-primary ${
                      selectedCategory === "all" ? "active" : ""
                    }`}
                    onClick={() => setSelectedCategory("all")}
                  >
                    Ver todos
                  </a>
                  <a
                    href="#"
                    className={`btn btn-sm btn-neutral ${
                      selectedCategory === "RRHH" ? "active" : ""
                    }`}
                    onClick={() => setSelectedCategory("RRHH")}
                  >
                    RRHH <span className="text-muted text-xs"></span>
                  </a>
                  <a
                    href="#"
                    className={`btn btn-sm btn-neutral ${
                      selectedCategory === "Admin" ? "active" : ""
                    }`}
                    onClick={() => setSelectedCategory("Admin")}
                  >
                    Admin <span className="text-muted text-xs"></span>
                  </a>
                </div>
              </div>
              <div className="ms-auto hstack gap-2">
                <div className="input-group input-group-sm input-group-inline">
                  <span className="input-group-text pe-2">
                    <i className="bi bi-search"></i>{" "}
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Buscar"
                    aria-label="Buscar"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
                <div>
                  <button
                    type="button"
                    className="btn btn-sm px-3 btn-primary d-flex align-items-center"
                    onClick={() => ctx.openNewUserModal()}
                  >
                    <i className="bi bi-plus-square-dotted me-2"></i>{" "}
                    <span>Nuevo</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-hover table-nowrap">
                <thead className="table-light">
                  <tr>
                    <th scope="col">Nombre</th>
                    <th scope="col">Correo electrónico</th>
                    <th scope="col">Acceso</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {filteredUsers.map((user, index) => (
                    <UserList key={index} user={user} />
                  ))}
                </tbody>
              </table>
              {ctx.showDeleteModal && (
                <DeleteModal
                  title={"Eliminar usuario"}
                  onClose={() => {
                    ctx.closeDeleteModal();
                    ctx.launchRefreshUserList();
                  }}
                  message1={`¿Estás seguro de eliminar el usuario ${ctx.selectedUser.name} ${ctx.selectedUser.lastname}?`}
                  message2="¡Cuidado! Eliminar el usuario implica eliminar tambien todas sus cards asociadas"
                  apiUrl={apiUrls.users.adminsDelete(ctx.selectedUser.sub)}
                />
              )}
              {ctx.showEditModal && (
                <EditUserModal
                  user={ctx.selectedUser}
                  onClose={() => {
                    ctx.closeEditUserModal();
                    ctx.launchRefreshUserList();
                  }}
                />
              )}
              {ctx.showNewUserModal && (
                <NewUserModal
                  onClose={() => {
                    ctx.closeNewUserModal();
                    ctx.launchRefreshUserList();
                  }}
                />
              )}
            </div>
            <div className="card-footer border-0 py-5">
              <Paginator
                currentPage={currentPage}
                totalPages={Math.max(1, Math.ceil(admins.count / pageSize))}
                onPageChange={onPageChange}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ListUsers;
