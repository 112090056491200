export function Group({ group }) {
  return (
    <tr key={group.id}>
      <td>
        <a className="text-heading text-primary-hover font-semibold" href="#">
          {group.name}
        </a>
      </td>
      <td>{group.description.substring(0, 30)}</td>
      <td>{group.cards}</td>
    </tr>
  );
}
