import { v4 as uudiv4 } from "uuid";
import { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useMetrics } from "../../context/metricsContext";
import { useAuth } from "../../context/authcontext";
import { getWithAuth } from "../../api/api";
import { apiUrls } from "../../api/apiConfig";

export function Header({ setQuery }) {
  const ctx = useMetrics();
  const { getLoggedSession } = useAuth();
  const userSession = getLoggedSession();
  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState("all");
  const [selectedDate, setSelectedDate] = useState({ from: null, to: null });

  const datepickerIcon = (type) =>
    !selectedDate[type] ? (
      <i className="bi bi-calendar"></i>
    ) : (
      <span
        onClick={() =>
          setSelectedDate((prevState) => ({ ...prevState, [type]: null }))
        }
      >
        <i className="bi bi-x-circle-fill"></i>
      </span>
    );

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const response = await getWithAuth(
          apiUrls.corp.groupsList,
          userSession
        );
        if (response.status === "error") {
          ctx.openAlertHeader({ message: response.detail, type: "danger" });
        } else setGroups(response.results);
      } catch (error) {
        ctx.openAlertHeader({
          message: "Ha ocurrido un error conectando con el servidor",
          type: "danger",
        });
      }
    };
    fetchGroups();
  }, []);

  const changeDates = async (date, type) => {
    if (date === null)
      return setSelectedDate((prevState) => ({ ...prevState, [type]: null }));
    setSelectedDate((prevState) => ({ ...prevState, [type]: date }));
  };

  const buildQuery = () => {
    const startDate = selectedDate.from
      ? formatDate(selectedDate.from.toLocaleDateString())
      : null;
    const endDate = selectedDate.to
      ? formatDate(selectedDate.to.toLocaleDateString())
      : null;
    const dateQueryParam =
      startDate && endDate && endDate >= startDate
        ? `start_date=${startDate}&end_date=${endDate}`
        : null;
    setQuery((prevQuery) => ({
      ...prevQuery,
      group: selectedGroup,
      date: dateQueryParam,
    }));
  };

  const formatDate = (date) => {
    const [day, month, year] = date.split("/");
    return `${year}-${month < 10 ? `0${month}` : month}-${
      day < 10 ? `0${day}` : day
    }`;
  };

  return (
    <div className="d-flex justify-content-between p-7">
      <div>
        <div>
          <select
            onChange={(ev) => setSelectedGroup(ev.target.value)}
            value={selectedGroup}
            name="filterDate-by-group"
            className="form-select"
            aria-label="Default select example"
          >
            <option value={"all"}>Ver todos los grupos</option>
            {groups.map((group) => (
              <option key={uudiv4()} value={group.id}>
                {group.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="d-flex align-items-center gap-2">
        <DatePicker
          dateFormat="dd/MM/yyyy"
          showIcon
          icon={datepickerIcon("from")}
          selected={selectedDate.from}
          onSelect={(date) => changeDates(date, "from")}
          className="bg-white text-black text-center border border-dark rounded-1"
        />
        <DatePicker
          dateFormat="dd/MM/yyyy"
          showIcon
          icon={datepickerIcon("to")}
          selected={selectedDate.to}
          onSelect={(date) => changeDates(date, "to")}
          className="bg-white text-black text-center border border-dark rounded-1"
        />
        <button
          type="button"
          className="btn btn-sm px-3 btn-primary d-flex align-items-center"
          onClick={buildQuery}
        >
          <span className="me-2">Buscar </span> <i className="bi bi-search"></i>
        </button>
      </div>
    </div>
  );
}
