import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import AutoDismissAlert from "../../components/autoDismiss";
import { deleteWithAuth } from "../../api/api";
import { useAuth } from "../../context/authcontext";
import { apiUrls } from "../../api/apiConfig";

export function ActivateCardModal({ card, onClose }) {
  const { getLoggedSession } = useAuth();
  const [state, setState] = useState({
    alert: {
      show: false,
      message: "",
      type: "danger",
    },
  });

  const handleActivate = async () => {
    const userSession = getLoggedSession();
    try {
      const response = await deleteWithAuth(
        `${
          userSession.type_user === "owner"
            ? apiUrls.owners.cardsDelete(card.id)
            : apiUrls.cards.delete(card.id)
        }?activate=true`,
        userSession
      );
      if (response.status === "error") {
        setState((prev) => ({
          ...prev,
          alert: {
            show: true,
            message: response.detail,
            type: "warning",
          },
        }));
      } else {
        onClose();
      }
    } catch (error) {
      setState((prev) => ({
        ...prev,
        alert: {
          show: true,
          message: error.detail,
          type: "danger",
        },
      }));
    }
  };

  return (
    <Modal show={true} onHide={onClose} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>{"Reactivar tarjeta"}</Modal.Title>
      </Modal.Header>
      {state.alert.show && (
        <div className="m-5">
          <AutoDismissAlert
            message={state.alert.message}
            alertType={state.alert.type}
            onClose={() =>
              setState((prev) => ({
                ...prev,
                alert: { ...prev.alert, show: false },
              }))
            }
          />
        </div>
      )}
      <Modal.Body>
        <p>{`¿Estás seguro de volver a activar la card ${card.name}?`}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleActivate}>
          Activar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
