import React, { useState } from "react";
import CardContent from "../../list_cards/components/cardContent";
import CardContentNew from "../components/cardContentNew";

const NewCard = () => {
  return (
    <div className="container-fluid max-w-screen-md vstack gap-5">
      <CardContentNew />
    </div>
  );
};

export default NewCard;
