import { Modal } from "react-bootstrap";
import { NewCardModalContent } from "./newCard";

export function NewCardModal({ org, closeModal }) {
  return (
    <Modal show={true} onHide={() => closeModal("newCard")} size="xl" centered>
      <Modal.Header closeButton onClick={() => closeModal("newCard")}>
        <Modal.Title>Nueva tarjeta</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <NewCardModalContent org={org} closeModal={closeModal} />
      </Modal.Body>
    </Modal>
  );
}
