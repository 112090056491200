import React, { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { AuthContext } from "../context/authcontext";

const ProtectedRoute = ({ children, requiredPermissions }) => {
  const location = useLocation();
  const { getLoggedSession } = useContext(AuthContext);

  if (!getLoggedSession().isAuthenticated) {
    return <Navigate to={`/login?redirect=${location.pathname}`} />;
  }

  const hasRequiredPermission = requiredPermissions.some((permission) =>
    getLoggedSession().type_user.includes(permission)
  );

  if (!hasRequiredPermission) {
    //El usuario no tiene el permiso necesario
    return <Navigate to="/unauthorized" />;
  }

  return children;
};

export default ProtectedRoute;
