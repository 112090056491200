import React, { useContext, useEffect, useState } from "react";
import CardUser from "../components/card";
import AutoDismissAlert from "../../components/autoDismiss";
import { useListCards } from "../../context/listCardsContext";
import { apiUrls } from "../../api/apiConfig";
import { getWithAuth } from "../../api/api";
import { AuthContext } from "../../context/authcontext";
import DeleteModal from "../../components/deleteModal";
import EditCardModal from "../components/editCardModal";
import ChangeStateCardModal from "../components/cardChangeState";
import EditCardPicModal from "../components/editCardPicModal";
import { GroupModal } from "../components/groupModal";
import { Paginator } from "../../components/pagination";

const ListCards = () => {
  const { getLoggedSession } = useContext(AuthContext);
  const userSession = getLoggedSession();
  const ctx = useListCards();
  const [cards, setCards] = useState({ count: null, results: [] });
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 12;

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const data = await getWithAuth(
          userSession.type_user === "owner"
            ? `${apiUrls.owners.cardsList}?page=${currentPage}&per_page=${pageSize}`
            : `${apiUrls.cards.list}?page=${currentPage}&per_page=${pageSize}`,
          userSession
        );
        setCards(data);
      } catch (error) {
        ctx.setMessage("Ha ocurrido un error conectando con el servidor");
        ctx.setAlertType("danger");
        ctx.openAlertHeader();
        console.error("Error getting cards:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [ctx.apiSuccess, currentPage]);

  const onPageChange = (page) => setCurrentPage(page);

  const filteredCards = cards.results?.filter((card) => {
    const fullName =
      `${card.employee.name} ${card.employee.lastname}`.toLowerCase();
    const userEmail = card.employee.email?.toLowerCase();
    const userJob = card.employee.job?.toLowerCase();

    if (selectedCategory === "all") {
      return (
        fullName?.includes(searchTerm.toLowerCase()) ||
        userEmail?.includes(searchTerm.toLowerCase()) ||
        userJob?.includes(searchTerm.toLowerCase())
      );
    } else if (selectedCategory === "accepted") {
      return (
        card.validation.status === "accepted" &&
        (fullName?.includes(searchTerm.toLowerCase()) ||
          userEmail?.includes(searchTerm.toLowerCase()) ||
          userJob?.includes(searchTerm.toLowerCase()))
      );
    } else if (selectedCategory === "pending") {
      return (
        card.validation.status === "pending" &&
        (fullName?.includes(searchTerm.toLowerCase()) ||
          userEmail?.includes(searchTerm.toLowerCase()) ||
          userJob?.includes(searchTerm.toLowerCase()))
      );
    }
    return false;
  });

  if (isLoading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <div
          className="spinner-grow text-serveo"
          style={{ width: "10rem", height: "10rem" }}
          role="status"
        ></div>
      </div>
    );
  }

  return (
    <div className="container-fluid mb-4">
      {ctx.showAlertHeader && (
        <div className="mb-4">
          <AutoDismissAlert
            message={ctx.message}
            alertType={ctx.alertType}
            onClose={ctx.closeAlertHeader}
          />
        </div>
      )}
      <div className="vstack gap-6">
        <div className="d-flex flex-column flex-md-row gap-3 justify-content-between">
          <div className="d-flex gap-3">
            <div className="input-group input-group-sm input-group-inline">
              <span className="input-group-text pe-2">
                <i className="bi bi-search"></i>{" "}
              </span>
              <input
                type="text"
                className="form-control"
                placeholder="Buscar"
                aria-label="Buscar"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>
          <div className="btn-group">
            <a
              href="#"
              className={`btn btn-sm btn-neutral ${
                selectedCategory === "all" ? "active text-primary" : ""
              }`}
              onClick={() => setSelectedCategory("all")}
            >
              Todas
            </a>
            <a
              href="#"
              className={`btn btn-sm btn-neutral ${
                selectedCategory === "accepted" ? "active text-primary" : ""
              }`}
              onClick={() => setSelectedCategory("accepted")}
            >
              Aceptadas
            </a>
            <a
              href="#"
              className={`btn btn-sm btn-neutral ${
                selectedCategory === "pending" ? "active text-primary" : ""
              }`}
              onClick={() => setSelectedCategory("pending")}
            >
              Pendientes
            </a>
          </div>
        </div>
        <div className="row g-6">
          {filteredCards?.map((card, index) => (
            <CardUser key={index} card={card} />
          ))}
        </div>
      </div>
      <div className="w-100 mt-4 d-flex justifiy-content-center align-items-center">
        <Paginator
          currentPage={currentPage}
          totalPages={Math.max(1, Math.ceil(cards.count / pageSize))}
          onPageChange={onPageChange}
        />
      </div>
      {ctx.showDeleteModal && (
        <DeleteModal
          title={"Eliminar card"}
          onClose={() => {
            ctx.closeDeleteModal();
            ctx.changeApiSuccess();
          }}
          message1={`¿Estás seguro de eliminar la card de ${ctx.selectedCard.name}?`}
          message2="¡Cuidado, esta card ya no será accesible nunca más!"
          apiUrl={
            userSession.type_user === "owner"
              ? `${apiUrls.owners.cardsDelete(ctx.selectedCard.id)}`
              : `${apiUrls.cards.delete(ctx.selectedCard.id)}`
          }
        />
      )}
      {ctx.showEditModal && (
        <EditCardModal
          card={ctx.selectedCard}
          onClose={() => ctx.closeEditUserModal()}
        />
      )}
      {ctx.showChangeImageModal && (
        <EditCardPicModal
          card={ctx.selectedCard}
          onClose={() => {
            ctx.closeChangeImageModal();
            ctx.changeApiSuccess();
          }}
        />
      )}
      {ctx.showChangeStateModal && (
        <ChangeStateCardModal
          card={ctx.selectedCard}
          onClose={() => {
            ctx.closeChangeStateModal();
            ctx.changeApiSuccess();
          }}
        />
      )}
      {ctx.showAddGroupModal && (
        <GroupModal
          card={ctx.selectedCard}
          onClose={() => {
            ctx.closeAddGroupModal();
          }}
        />
      )}
    </div>
  );
};

export default ListCards;
