import React, { useContext, useState } from "react";
import logo from "../../assets/img/logo_white.png";
import logo_movil from "../../assets/img/logo.png";
import { useAuth } from "../../context/authcontext";
import { useCommon } from "../../context/commonContext";
import { apiUrls } from "../../api/apiConfig";
import { post } from "../../api/api";
import { useLocation, useNavigate } from "react-router-dom";
import AutoDismissAlert from "../../components/autoDismiss";

const Login = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");

  const auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const commonContext = useCommon();

  const handleLogin = async (e) => {
    e.preventDefault();

    setLoading(true);
    try {
      const response = await post(apiUrls.auth.login, {
        username: email, // Usamos el valor del email como username
        password: password, // Usamos el valor de la contraseña proporcionada
      });
      if (response.status === "success") {
        const { id_token, access_token, session_token } = response.result;
        auth.login(id_token, access_token, session_token);
        const redirectUrl = new URLSearchParams(location.search).get(
          "redirect"
        );
        if (redirectUrl) {
          navigate(redirectUrl);
        } else {
          navigate("/list-cards");
        }
      } else if (response.status === "error") {
        console.error("Error al iniciar sesión:", response);
        commonContext.setAlertTypeLogin("danger");
        commonContext.setMessageLogin(response.detail);
        commonContext.openAlertHeaderLogin();
      }
      // history.push('/dashboard');
    } catch (error) {
      console.error("Error al iniciar sesión:", error);
      commonContext.setAlertTypeLogin("danger");
      commonContext.setMessageLogin(error);
      commonContext.openAlertHeaderLogin();
    }
    setLoading(false);
  };

  return (
    <div className="px-5 py-5 p-lg-0 min-h-screen bg-surface-secondary d-flex flex-column justify-content-center">
      <div className="d-flex justify-content-center">
        <div className="col-lg-5 col-xl-4 p-12 p-xl-20 position-fixed start-0 top-0 h-screen overflow-y-hidden bg-primary d-none d-lg-flex flex-column">
          <a className="d-block" href="#">
            <img src={logo} className="h-20" alt="..."></img>
          </a>
          <div className="mt-32 mb-20">
            <h1 className="ls-tight font-bolder display-6 text-white mb-5">
              Gestiona tus tarjetas corporativas
            </h1>
            <p className="text-white text-opacity-80">
              Una única plataforma para gestionar las tarjetas de tu
              organización
            </p>
          </div>
        </div>
        <div className="col-12 col-md-9 col-lg-7 offset-lg-5 border-left-lg min-h-screen d-flex flex-column justify-content-center position-relative">
          <div className="text-center mb-12 d-lg-none">
            <a className="d-inline-block" href="#">
              <img
                src={logo_movil}
                className="heigh-logo-mobile-login"
                alt="..."
              ></img>
            </a>
          </div>
          <div className="py-lg-16 px-lg-20">
            <div className="row">
              <div className="col-lg-10 col-md-9 col-xl-6 mx-auto ms-xl-0">
                <div className="mt-10 mt-lg-5 mb-6 d-lg-block">
                  <span className="d-inline-block d-lg-block h1 mb-4 mb-lg-6 me-3">
                    👋
                  </span>
                  <h1 className="ls-tight font-bolder h2">Hola de Nuevo!</h1>
                </div>
                {commonContext.showAlertHeaderLogin && (
                  <div className="mb-4">
                    {/* Contenido para cuando isOkSaveConfig o isOkSavePassword son verdaderos */}
                    <AutoDismissAlert
                      message={commonContext.messageLogin}
                      alertType={commonContext.alertTypeLogin}
                      onClose={commonContext.closeAlertHeaderLogin}
                    />
                    {/* Más contenido para cuando isOkSaveConfig o isOkSavePassword son verdaderos */}
                  </div>
                )}
                <form onSubmit={handleLogin}>
                  <div className="mb-5">
                    <label className="form-label" htmlFor="email">
                      Correo electrónico
                    </label>{" "}
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    ></input>
                  </div>
                  <div className="mb-1">
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <label className="form-label" htmlFor="password">
                          Contraseña
                        </label>
                      </div>
                      <div className="mb-2">
                        <a
                          href="/forgot-password"
                          className="text-sm text-primary-mini"
                        >
                          ¿Has olvidado tu contraseña?
                        </a>
                      </div>
                    </div>
                    <input
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="form-control"
                      id="password"
                      autoComplete="current-password"
                    ></input>
                  </div>

                  <div>
                    <button
                      type="submit"
                      href="#"
                      className="btn btn-primary w-full mt-5"
                    >
                      {loading ? <p>Cargando...</p> : <p>Acceder</p>}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
