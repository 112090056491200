import React, { useEffect, useState, useContext } from "react";
import DeleteModal from "../../components/deleteModal";
import { getWithAuth } from "../../api/api";
import { apiUrls } from "../../api/apiConfig";
import AutoDismissAlert from "../../components/autoDismiss";
import { AuthContext } from "../../context/authcontext";
import { Loading } from "../../components/loading";
import { Paginator } from "../../components/pagination";
import { NewGroupModal } from "../components/newGroup";
import { EditGroupModal } from "../components/editGroup";
import { useGroups } from "../../context/groupContext";
import { GroupList } from "../components/listGroups";

export function Groups() {
  const { getLoggedSession } = useContext(AuthContext);
  const ctx = useGroups();
  const [isLoading, setIsLoading] = useState(true);
  const [groups, setGroups] = useState({ count: null, results: [] });
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 12;

  useEffect(() => {
    const userSession = getLoggedSession();
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const data = await getWithAuth(
          `${apiUrls.corp.groupsList}?page=${currentPage}&per_page=${pageSize}`,
          userSession
        );
        setGroups(data);
      } catch (error) {
        ctx.setMessage("Ha ocurrido un error conectando con el servidor");
        ctx.setAlertType("danger");
        ctx.openAlertHeader();
        console.error("Error fetching admin users data:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [currentPage, ctx.launchRefreshGroupList]);

  const onPageChange = (page) => setCurrentPage(page);

  if (isLoading) return <Loading />;

  return (
    <div className="container-fluid ">
      {ctx.showAlertHeader && (
        <div className="">
          <AutoDismissAlert
            message={ctx.message}
            alertType={ctx.alertType}
            onClose={ctx.closeAlertHeader}
          />
        </div>
      )}
      <div className="vstack gap-4 mt-4">
        <div className="card">
          <div className="card-header d-flex align-items-center">
            <div className="ms-auto hstack gap-2">
              <div>
                <button
                  type="button"
                  className="btn btn-sm px-3 btn-primary d-flex align-items-center"
                  onClick={() => ctx.openModal("newGroup")}
                >
                  <i className="bi bi-plus-square-dotted me-2"></i>{" "}
                  <span>Nuevo</span>
                </button>
              </div>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table table-hover table-nowrap">
              <thead className="table-light">
                <tr>
                  <th scope="col">Nombre</th>
                  <th scope="col">Descripción</th>
                  <th scope="col">Nº de tarjetas</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {groups.results?.map((group, index) => (
                  <GroupList key={index} group={group} />
                ))}
              </tbody>
            </table>
            {ctx.showModal.delete && (
              <DeleteModal
                title={"Eliminar grupo"}
                onClose={() => {
                  ctx.closeModal("delete");
                  ctx.launchRefreshGroupList();
                }}
                message1={`¿Estás seguro de eliminar el grupo ${ctx.selectedGroup.name}?`}
                message2=""
                apiUrl={apiUrls.corp.groupsDelete(ctx.selectedGroup.id)}
              />
            )}
            {ctx.showModal.editGroup && (
              <EditGroupModal group={ctx.selectedGroup} />
            )}
            {ctx.showModal.newGroup && <NewGroupModal />}
          </div>
          <div className="card-footer border-0 py-5">
            <Paginator
              currentPage={currentPage}
              totalPages={Math.max(1, Math.ceil(groups.count / pageSize))}
              onPageChange={onPageChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
