import { useState, useRef } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

export function FileUploader({ image, setImage, setLogo }) {
  const [fileSend, setFileSend] = useState(null);
  const [cropData, setCropData] = useState(null);
  const cropperRef = useRef(null);
  const fileInputRef = useRef(null);
  const [croppedImage, setCroppedImage] = useState(false);

  const onChange = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };

  const getCropData = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      setCropData(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
      const canvas = cropperRef.current?.cropper.getCroppedCanvas();
      if (canvas) {
        canvas.toBlob((blob) => {
          if (blob) {
            const file = new File([blob], "cropped-image.png", {
              type: "image/png",
            });
            setFileSend(file);
            setLogo(file);
          }
        }, "image/png");
      }
      setCroppedImage(true);
    }
  };

  return (
    <div>
      <button
        type="button"
        onClick={() => fileInputRef.current.click()}
        className="mb-4 btn btn-sm btn-neutral"
      >
        Subir nuevo archivo
      </button>
      <div className="d-flex align-items-center">
        <input
          type="file"
          accept="image/png"
          style={{ display: "none" }}
          ref={fileInputRef}
          onChange={onChange}
        />

        <Cropper
          style={{ height: 300, width: "50%" }}
          aspectRatio={5 / 1}
          src={image}
          ref={cropperRef}
          guides={true}
          background={true}
          responsive={true}
          preview=".img-preview"
          className="bg-white"
        />
      </div>
      <button
        type="button"
        className="mt-3 btn btn-sm px-3 btn-primary d-flex align-items-center"
        onClick={getCropData}
      >
        <i className="bi bi-plus-square-dotted me-2"></i>
        <span>Establecer recorte</span>
      </button>
      {cropData && (
        <>
          <div className="d-flex align-items-center">
            <span className="mt-3">
              Esta imagen aparecerá como el logo de la organización
            </span>
          </div>
          <img className="fixed-height-cropper mt-4" alt="..." src={cropData} />
        </>
      )}
    </div>
  );
}
