import React, { createContext, useContext, useState } from "react";

const ListCardsContext = createContext();

export const ListCardsProvider = ({ children }) => {
  const [selectedCard, setSelectedCard] = useState(null);

  //Gestion del contenido de los Alert
  const [message, setMessage] = useState("Esto un mensaje de prueba");
  const [alertType, setAlertType] = useState("success");

  //Gestion del Alert del Header
  const [showAlertHeader, setShowAlertHeader] = useState(false);
  const openAlertHeader = () => {
    setShowAlertHeader(true);
  };
  const closeAlertHeader = () => {
    setShowAlertHeader(false);
  };

  //Gestion del modal Edicion de usuario
  const [showEditModal, setShowEditModal] = useState(false);
  const openEditUserModal = (card) => {
    setSelectedCard(card);
    setShowEditModal(true);
  };
  const closeEditUserModal = () => {
    setShowEditModal(false);
  };

  //Gestion del modal de borrar un usuario
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const openDeleteModal = (card) => {
    setSelectedCard(card);
    setShowDeleteModal(true);
  };
  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  //Gestion del modal de cambio de estado
  const [showChangeStateModal, setShowChangeStateModal] = useState(false);
  const openChangeStateModal = (card) => {
    setSelectedCard(card);
    setShowChangeStateModal(true);
  };
  const closeChangeStateModal = () => {
    setShowChangeStateModal(false);
  };

  //Gestion del modal de cambio de imagen
  const [showChangeImageModal, setShowChangeImageModal] = useState(false);
  const openChangeImageModal = (card) => {
    setSelectedCard(card);
    setShowChangeImageModal(true);
  };
  const closeChangeImageModal = () => {
    setShowChangeImageModal(false);
  };

  //Gestion del modal de agregar grupo
  const [showAddGroupModal, setShowAddGroupModal] = useState(false);
  const openAddGroupModal = (card) => {
    setSelectedCard(card);
    setShowAddGroupModal(true);
  };
  const closeAddGroupModal = () => setShowAddGroupModal(false);

  const [apiSuccess, setApiSuccess] = useState(false);
  const changeApiSuccess = () => {
    setApiSuccess(!apiSuccess);
  };
  return (
    <ListCardsContext.Provider
      value={{
        apiSuccess,
        changeApiSuccess,
        closeChangeStateModal,
        openChangeStateModal,
        showChangeStateModal,
        closeChangeImageModal,
        openChangeImageModal,
        showChangeImageModal,
        closeDeleteModal,
        openDeleteModal,
        showDeleteModal,
        alertType,
        setAlertType,
        setMessage,
        message,
        openAlertHeader,
        closeAlertHeader,
        showAlertHeader,
        selectedCard,
        setSelectedCard,
        showEditModal,
        openEditUserModal,
        closeEditUserModal,
        showAddGroupModal,
        openAddGroupModal,
        closeAddGroupModal,
      }}
    >
      {children}
    </ListCardsContext.Provider>
  );
};

export const useListCards = () => {
  return useContext(ListCardsContext);
};
