import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useOrgs } from "../../context/orgsContext";
import { apiUrls } from "../../api/apiConfig";
import { getWithAuth } from "../../api/api";
import { AuthContext } from "../../context/authcontext";
import { UserCard } from "./userCard";
import { Paginator } from "../../components/pagination";

export function ListCardsModal({ org, closeModal }) {
  const { getLoggedSession } = useContext(AuthContext);
  const userSession = getLoggedSession();
  const ctx = useOrgs();
  const [cards, setCards] = useState({ count: null, results: [] });
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 12;

  useEffect(() => {
    const fetchCards = async () => {
      try {
        const response = await getWithAuth(
          `${apiUrls.owners.cardsList}?org_name=${org.name}&page=${currentPage}&per_page=${pageSize}`,
          userSession
        );
        setCards({ ...response });
      } catch (error) {
        ctx.openAlertHeader({
          message: "Ha ocurrido un error conectando con el servidor",
          type: "danger",
        });
      } finally {
        setIsLoading(false);
        ctx.closeAlertHeader();
      }
    };
    fetchCards();
  }, [currentPage]);

  const onPageChange = (page) => setCurrentPage(page);

  return (
    <Modal
      show={true}
      onHide={() => closeModal("listCards")}
      size="xl"
      centered
    >
      <Modal.Header closeButton onClick={() => closeModal("listCards")}>
        <Modal.Title>Listar tarjetas</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="vstack gap-6">
          <div className="row g-6">
            {isLoading ? (
              <div className="d-flex justify-content-center align-items-center">
                <div
                  className="spinner-grow text-serveo"
                  style={{ width: "10rem", height: "10rem" }}
                  role="status"
                ></div>
              </div>
            ) : (
              cards.results?.map((card) => (
                <div key={card.id} className="col-xl-3 col-sm-6 gap-2">
                  <UserCard card={card} />
                </div>
              ))
            )}
          </div>
        </div>
        <div className="w-100 mt-4 d-flex justifiy-content-center align-items-center">
          <Paginator
            currentPage={currentPage}
            totalPages={Math.max(1, Math.ceil(cards.count / pageSize))}
            onPageChange={onPageChange}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}
