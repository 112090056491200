import React, { useEffect, useState } from "react";
import { UserCard } from "../components/card";
import AutoDismissAlert from "../../components/autoDismiss";
import { apiUrls } from "../../api/apiConfig";
import { getWithAuth } from "../../api/api";
import DeleteModal from "../../components/deleteModal";
import { ActivateCardModal } from "../components/activateCardModal";
import { Paginator } from "../../components/pagination";
import { useAuth } from "../../context/authcontext";
import { Loading } from "../../components/loading";

export function ListDeactivatedCards() {
  const { getLoggedSession } = useAuth();
  const userSession = getLoggedSession();
  const pageSize = 12;
  const [response, setResponse] = useState({
    count: 1,
    results: [],
    isLoading: true,
  });
  const [state, setState] = useState({
    modal: {
      show: false,
      type: "",
    },
    alert: {
      show: false,
      message: "",
      type: "danger",
    },
    selectedCard: null,
    currentPage: 1,
    refresh: false,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getWithAuth(
          `${
            userSession.type_user === "owner"
              ? apiUrls.owners.cardsList
              : apiUrls.cards.list
          }?is_active=false&page=${state.currentPage}&per_page=${pageSize}`,
          userSession
        );
        setResponse((prev) => ({
          ...prev,
          count: res.count,
          results: res.results,
        }));
      } catch (error) {
        setState((prev) => ({
          ...prev,
          alert: {
            show: true,
            message: "Ha ocurrido un error conectando con el servidor",
            type: "danger",
          },
        }));
      } finally {
        setResponse((prev) => ({ ...prev, isLoading: false }));
      }
    };
    fetchData();
  }, [state.currentPage, state.refresh]);

  const onPageChange = (page) =>
    setState((prev) => ({ ...prev, currentPage: page }));

  const openModal = (type, card) =>
    setState((prev) => ({
      ...prev,
      modal: { show: true, type },
      selectedCard: card,
    }));

  if (response.isLoading) return <Loading />;

  return (
    <div className="container-fluid mb-4">
      {alert.show && (
        <div className="mb-4">
          <AutoDismissAlert
            message={alert.message}
            alertType={alert.type}
            onClose={() =>
              setState((prev) => ({
                ...prev,
                alert: { ...prev.alert, show: false },
              }))
            }
          />
        </div>
      )}
      <div className="vstack gap-6">
        <div className="row g-6">
          {response.results?.map((card, index) => (
            <UserCard key={index} card={card} openModal={openModal} />
          ))}
        </div>
      </div>
      <div className="w-100 mt-4 d-flex justifiy-content-center align-items-center">
        <Paginator
          currentPage={state.currentPage}
          totalPages={Math.max(1, Math.ceil(response.count / pageSize))}
          onPageChange={onPageChange}
        />
      </div>
      {state.modal.type === "delete" && (
        <DeleteModal
          title={"Eliminar card"}
          onClose={() =>
            setState((prev) => ({
              ...prev,
              modal: { show: false, type: "" },
              refresh: !prev.refresh,
            }))
          }
          message1={`¿Estás seguro de eliminar la card de ${state.selectedCard.name}?`}
          message2="¡Cuidado, esta card ya no será accesible nunca más!"
          apiUrl={`${
            userSession.type_user === "owner"
              ? apiUrls.owners.cardsDelete(state.selectedCard.id)
              : apiUrls.cards.delete(state.selectedCard.id)
          }?deactivate=false`}
        />
      )}
      {state.modal.type === "activate" && (
        <ActivateCardModal
          card={state.selectedCard}
          onClose={() =>
            setState((prev) => ({
              ...prev,
              modal: { show: false, type: "" },
              refresh: !prev.refresh,
            }))
          }
        />
      )}
    </div>
  );
}
