import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Paginator } from "../../components/pagination";

function MetricsRow({ data }) {
  return (
    <tr>
      <td>{data.employee.name}</td>
      <td>{data.groups.length > 0 ? data.groups.join(", ") : "N / D"}</td>
      <td>{data.metrics.views}</td>
      <td>{data.metrics.calls}</td>
      <td>{data.metrics.conversion}</td>
    </tr>
  );
}

export function MetricsTable({ count, metrics, isLoading, setQuery }) {
  const [sortTerm, setSortTerm] = useState("views");
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 12;

  const sortMetrics = () =>
    metrics.sort((a, b) => b.metrics[sortTerm] - a.metrics[sortTerm]);

  const onPageChange = (page) => {
    setCurrentPage(page);
    setQuery((prevQuery) => ({
      ...prevQuery,
      pagination: `page=${page}&per_page=${pageSize}`,
    }));
  };

  return (
    <div className="container-fluid">
      <div className="vstack gap-4">
        <div className="card">
          <div className="card-header d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">Empleados</div>
            <div className="d-flex align-items-center">
              <label htmlFor="metrics-sort-by" className="form-label mx-5">
                Ordenar por:{" "}
              </label>
              <select
                onChange={(ev) => setSortTerm(ev.target.value)}
                name="status"
                id="metrics-sort-by"
                className="form-select mx-5"
                aria-label="Default select example"
              >
                <option value="views">Visualizaciones</option>
                <option value="calls">Llamadas</option>
                <option value="conversion">Tasa de conversión</option>
              </select>
              <div className="ms-auto hstack gap-2">
                <div>
                  <button
                    type="button"
                    className="btn btn-sm px-3 btn-primary d-flex align-items-center"
                    onClick={null}
                  >
                    <span className="me-2">Exportar</span>
                    <i className="bi bi-caret-right-fill"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          {isLoading ? (
            <div className="d-flex justify-content-center align-items-center">
              <div
                className="spinner-grow text-serveo"
                style={{ width: "10rem", height: "10rem" }}
                role="status"
              ></div>
            </div>
          ) : (
            <div className="table-responsive">
              <table className="table table-hover table-nowrap">
                <thead className="table-light">
                  <tr>
                    <th scope="col">Nombre</th>
                    <th scope="col">Grupo</th>
                    <th scope="col">Total de visualizaciones</th>
                    <th scope="col">Total de llamadas</th>
                    <th scope="col">Tasa de conversión</th>
                  </tr>
                </thead>
                <tbody>
                  {sortMetrics().map((employee) => (
                    <MetricsRow key={uuidv4()} data={employee} />
                  ))}
                </tbody>
              </table>
            </div>
          )}
          <div className="card-footer border-0 py-5 d-flex justify-content-center">
            <Paginator
              currentPage={currentPage}
              totalPages={Math.max(1, Math.floor(count / pageSize))}
              onPageChange={onPageChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
