import { Modal } from "react-bootstrap";
import { NewSuperadminModalContent } from "./newSuperadmin";

export function NewSuperadminModal({ org, closeModal }) {
  return (
    <Modal
      show={true}
      onHide={() => closeModal("newSuperadmin")}
      size="xl"
      centered
    >
      <Modal.Header closeButton onClick={() => closeModal("newSuperadmin")}>
        <Modal.Title>Nuevo administrador</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <NewSuperadminModalContent org={org} closeModal={closeModal} />
      </Modal.Body>
    </Modal>
  );
}
