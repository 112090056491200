import imageProfile from "../../assets/img/img-profile.jpg";
import TimestampDisplay from "../../components/timeStampShow";

const CommentCard = ({ comment }) => {
  const imagePic = comment.admin.user_pic_url
    ? comment.admin.user_pic_url
    : imageProfile;

  return (
    <div className="card mt-3">
      <div className="px-4 px-md-8 pb-4">
        <div className="d-flex align-items-center mb-4 py-5 border-bottom">
          <h5 className="font-semibold">
            <img
              alt="..."
              src={imagePic}
              className="avatar avatar-sm rounded-circle me-2"
            ></img>{" "}
            <span>{`${comment.admin.name} ${comment.admin.lastname}`}</span>
          </h5>
          <div className="m-2">
            <TimestampDisplay timestamp={comment.timestamp} />
          </div>
          <div className="hstack gap-3 ms-auto">
            <a href="#" className="p-1 text-muted"></a>
          </div>
        </div>
        <div className="list-group list-group-flush">
          <div className="list-group-item py-1 border-0">
            <div className="row g-3 align-items-center">
              <div className="col-sm-6 col-12">
                <div className="form-check form-check-linethrough">
                  <a
                    href="#offcanvasCreate"
                    className="form-check-label font-regular ms-2"
                    data-bs-toggle="offcanvas"
                  >
                    {comment.text ? (
                      <em>{comment.text}</em>
                    ) : (
                      "No se añade ningún comentario"
                    )}
                  </a>
                </div>
              </div>
              <div className="col-sm-auto col-12 ms-sm-auto">
                <div className="hstack gap-5 justify-content-end">
                  {comment.status === "accepted" && (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="17"
                        viewBox="0 0 16 17"
                        fill="none"
                      >
                        <g clipPath="url(#clip0_459_26848)">
                          <path
                            d="M15.75 8.5C15.75 12.7802 12.2802 16.25 8 16.25C3.71978 16.25 0.25 12.7802 0.25 8.5C0.25 4.21978 3.71978 0.75 8 0.75C12.2802 0.75 15.75 4.21978 15.75 8.5ZM7.10356 12.6036L12.8536 6.85356C13.0488 6.65831 13.0488 6.34172 12.8536 6.14647L12.1465 5.43937C11.9512 5.24409 11.6346 5.24409 11.4393 5.43937L6.75 10.1287L4.56066 7.93934C4.36541 7.74409 4.04881 7.74409 3.85353 7.93934L3.14644 8.64644C2.95119 8.84169 2.95119 9.15828 3.14644 9.35353L6.39644 12.6035C6.59172 12.7988 6.90828 12.7988 7.10356 12.6036Z"
                            fill="#00A3FF"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_459_26848">
                            <rect
                              width="16"
                              height="16"
                              fill="white"
                              transform="translate(0 0.5)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      <span className="me-2 text-accepted font-semibold">
                        Aceptada
                      </span>
                    </>
                  )}
                  {comment.status === "pending" && (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className=" text-pending bi bi-clock-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                      </svg>
                      <span className="me-2 text-pending font-semibold">
                        Pendiente
                      </span>
                    </>
                  )}
                  {comment.status === "rejected" && (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="text-rejected bi bi-x-circle-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                      </svg>
                      <span className="me-2 text-rejected font-semibold">
                        Rechazada
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommentCard;
