import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import NewCard from "../new_card/pages/new";
import MainLayout from "../layouts/MainLayout";
import ListCards from "../list_cards/pages/list";
import ListUsers from "../users_admin/pages/users";
import Profile from "../profile/pages/profile";
import Login from "../base/pages/login";
import ProtectedRoute from "../components/protectedRoute";
import { ADMIN_USER, GENERAL_USER, OWNER_USER } from "./permissions";
import { AuthProvider } from "../context/authcontext";
import Logout from "../base/pages/logout";
import { UserProvider } from "../context/userContext";
import { SettingProvider } from "../context/settingContext";
import { ListCardsProvider } from "../context/listCardsContext";
import { CommonProvider } from "../context/commonContext";
import ForgotPassword from "../base/pages/forgot-password";
import ResetPassword from "../base/pages/reset-password";
import { Metrics } from "../metrics/pages/metrics";
import { MetricsProvider } from "../context/metricsContext";
import { OrgsProvider } from "../context/orgsContext";
import { Organizations } from "../organizations/pages/organizations";
import { NewOrg } from "../new_org/pages/new_org";
import { Groups } from "../groups/pages/groups";
import { GroupProvider } from "../context/groupContext";
import { ListDeactivatedCards } from "../deactivated_cards/pages/list";

const CardsRoutes = () => {
  return (
    <CommonProvider>
      <AuthProvider>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/" element={<Navigate to="/login" />} />
          <Route element={<MainLayout />}>
            <Route
              path="/new-card"
              element={
                <ProtectedRoute
                  requiredPermissions={[ADMIN_USER, GENERAL_USER, OWNER_USER]}
                >
                  <NewCard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/groups"
              element={
                <GroupProvider>
                  <ProtectedRoute
                    requiredPermissions={[ADMIN_USER, GENERAL_USER]}
                  >
                    <Groups />
                  </ProtectedRoute>
                </GroupProvider>
              }
            />
            <Route
              path="/new-organization"
              element={
                <OrgsProvider>
                  <ProtectedRoute requiredPermissions={[OWNER_USER]}>
                    <NewOrg />
                  </ProtectedRoute>
                </OrgsProvider>
              }
            />
            <Route
              path="/organizations"
              element={
                <OrgsProvider>
                  <ProtectedRoute requiredPermissions={[OWNER_USER]}>
                    <Organizations />
                  </ProtectedRoute>
                </OrgsProvider>
              }
            />
            <Route
              path="/list-cards"
              element={
                <ListCardsProvider>
                  <ProtectedRoute
                    requiredPermissions={[ADMIN_USER, GENERAL_USER, OWNER_USER]}
                  >
                    <ListCards />
                  </ProtectedRoute>
                </ListCardsProvider>
              }
            />
            <Route
              path="/deactivated-cards"
              element={
                <ProtectedRoute
                  requiredPermissions={[ADMIN_USER, GENERAL_USER, OWNER_USER]}
                >
                  <ListDeactivatedCards />
                </ProtectedRoute>
              }
            />
            <Route
              path="/metrics"
              element={
                <MetricsProvider>
                  <ProtectedRoute
                    requiredPermissions={[ADMIN_USER, GENERAL_USER]}
                  >
                    <Metrics />
                  </ProtectedRoute>
                </MetricsProvider>
              }
            />
            <Route
              path="/admin-users"
              element={
                <UserProvider>
                  <ProtectedRoute requiredPermissions={[ADMIN_USER]}>
                    <ListUsers />
                  </ProtectedRoute>
                </UserProvider>
              }
            />
            <Route
              path="/profile"
              element={
                <SettingProvider>
                  <ProtectedRoute
                    requiredPermissions={[ADMIN_USER, GENERAL_USER, OWNER_USER]}
                  >
                    <Profile />
                  </ProtectedRoute>
                </SettingProvider>
              }
            />
          </Route>
          <Route path="/logout" element={<Logout />} />
        </Routes>
      </AuthProvider>
    </CommonProvider>
  );
};

export default CardsRoutes;
