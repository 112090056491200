import { Card } from "react-bootstrap";

export function UserCard({ card }) {
  return (
    <Card>
      <Card.Body className="d-flex flex-column align-items-center">
        <div className="rounded">
          <img
            alt="..."
            src={card.config.card_pic_url}
            className="fixed-height-image"
          ></img>
        </div>
        <p className="h5 d-block mt-4">{card.employee.name ?? "No definido"}</p>
        <p className="h6 d-block mt-0">
          {card.employee.lastname ?? "No definido"}
        </p>
        <span className="d-block text-sm text-muted mt-2">
          {card.employee.job ?? "No definido"}
        </span>
        <span className="d-block text-sm text-muted">
          {card.employee.email ?? "No definido"}
        </span>
        <span className="d-block text-sm text-muted">
          Views: <span className="ml-2">{card.metrics.views}</span>
        </span>
        <span className="d-block text-sm text-muted">
          Conversion: <span className="ml-2">{card.metrics.conversion}</span>
        </span>
      </Card.Body>
    </Card>
  );
}
