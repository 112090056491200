import React, { useState } from "react";
import AutoDismissAlert from "../../components/autoDismiss";
import { apiUrls } from "../../api/apiConfig";
import { Form, Button, Card } from "react-bootstrap";
import { postWithAuth } from "../../api/api";
import { useAuth } from "../../context/authcontext";

export function NewSuperadminModalContent({ org, closeModal }) {
  const [alertHeader, setAlertHeader] = useState({
    message: null,
    type: null,
    show: false,
  });
  const { getLoggedSession } = useAuth();
  const userSession = getLoggedSession();
  const [newAdmin, setNewAdmin] = useState({
    email: "",
    name: "",
    lastname: "",
    password: "",
  });

  const handleChange = (ev) => {
    const { id, value } = ev.target;
    setNewAdmin({ ...newAdmin, [id]: value });
  };

  const handleSave = async (ev) => {
    ev.preventDefault();
    try {
      const response = await postWithAuth(
        apiUrls.owners.newSuperadmin,
        newAdmin,
        userSession
      );
      if (response.status === "success") {
        closeModal("newSuperadmin");
      }
    } catch (error) {
      setAlertHeader({
        message: "Ha ocurrido un error conectando con el servidor",
        type: "danger",
      });
    }
  };

  return (
    <div className="container-fluid max-w-screen-md vstack gap-5">
      {/***
       * Alert
       ***/}
      {alertHeader.show && (
        <div className="mb-5">
          <AutoDismissAlert
            message={alertHeader.message}
            alertType={alertHeader.type}
            onClose={() =>
              setAlertHeader({ message: null, type: null, show: false })
            }
          />
        </div>
      )}
      <Card>
        <Card.Body>
          <Form onSubmit={handleSave}>
            <Form.Group className="mb-4" controlId="name">
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                value={newAdmin.name}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-4" controlId="lastname">
              <Form.Label>Apellido</Form.Label>
              <Form.Control
                type="text"
                value={newAdmin.lastname}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-4" controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="text"
                value={newAdmin.email}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-4" controlId="password">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="text"
                value={newAdmin.password}
                onChange={handleChange}
              />
            </Form.Group>
            <div className="d-flex mb-4 flex-row-reverse">
              <Button
                className="btn btn-sm btn-neutral my-2 mx-2"
                onClick={() => closeModal("newSuperadmin")}
              >
                Cancelar
              </Button>
              <Button
                type="submit"
                className="btn btn-sm btn-primary my-2 mx-2"
              >
                Guardar
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
}
