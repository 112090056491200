import { Pagination } from "react-bootstrap";

export const Paginator = ({ currentPage, totalPages, onPageChange }) => {
  const disableFirst = currentPage === 1;
  const disableLast = currentPage === totalPages;

  return (
    <Pagination>
      <Pagination.First
        linkStyle={
          disableFirst
            ? { borderWidth: "1px" }
            : { color: "#DA4181", borderWidth: "1px", borderColor: "#202A4A" }
        }
        onClick={() => onPageChange(1)}
        disabled={disableFirst}
      />
      <Pagination.Prev
        linkStyle={
          disableFirst
            ? { borderWidth: "1px" }
            : { color: "#DA4181", borderWidth: "1px", borderColor: "#202A4A" }
        }
        onClick={() => onPageChange(currentPage - 1)}
        disabled={disableFirst}
      />
      <Pagination.Item
        linkStyle={{
          backgroundColor: "#FFFFFF",
          color: "#DA4181",
          borderWidth: "1px",
          borderColor: "#202A4A",
        }}
        active
      >
        {currentPage}
      </Pagination.Item>
      <Pagination.Next
        linkStyle={
          disableLast
            ? { borderWidth: "1px" }
            : { color: "#DA4181", borderWidth: "1px", borderColor: "#202A4A" }
        }
        onClick={() => onPageChange(currentPage + 1)}
        disabled={disableLast}
      />
      <Pagination.Last
        linkStyle={
          disableLast
            ? { borderWidth: "1px" }
            : { color: "#DA4181", borderWidth: "1px", borderColor: "#202A4A" }
        }
        onClick={() => onPageChange(totalPages)}
        disabled={disableLast}
      />
    </Pagination>
  );
};
