import { Modal, Form, Button } from "react-bootstrap";
import { useState } from "react";
import { postWithAuth } from "../../api/api";
import { apiUrls } from "../../api/apiConfig";
import { useAuth } from "../../context/authcontext";
import { useGroups } from "../../context/groupContext";

export function EditGroupModal({ onClose, group }) {
  const { getLoggedSession } = useAuth();
  const userSession = getLoggedSession();
  const ctx = useGroups();
  const [patchedGroup, setPatchedGroup] = useState({
    name: group.name,
    description: group.description,
  });

  const handleInputChange = (ev) => {
    const { id, value } = ev.target;
    setPatchedGroup({ ...patchedGroup, [id]: value });
  };

  const handleSave = async (ev) => {
    ev.preventDefault();
    try {
      await postWithAuth(
        apiUrls.corp.groupsPatch(group.id),
        patchedGroup,
        userSession
      );
    } catch (error) {
      ctx.openAlertHeader({
        message: "Ha ocurrido un error conectando con el servidor",
        type: "danger",
      });
    } finally {
      ctx.closeModal("editGroup");
      ctx.launchRefreshGroupList();
    }
  };

  return (
    <Modal
      show={true}
      onHide={() => ctx.closeModal("editGroup")}
      size="lg"
      centered
    >
      <Modal.Header closeButton onClick={() => ctx.closeModal("editGroup")}>
        <Modal.Title>Editar grupo</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container-fluid max-w-screen-md vstack gap-6">
          <Form onSubmit={handleSave}>
            <Form.Group className="mb-4" controlId="name">
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                value={patchedGroup.name}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group className="mb-4" controlId="description">
              <Form.Label>Descripción</Form.Label>
              <Form.Control
                type="text"
                value={patchedGroup.description}
                onChange={handleInputChange}
              />
            </Form.Group>
            <div className="d-flex mb-4 flex-row-reverse">
              <Button
                className="btn btn-sm btn-neutral my-2 mx-2"
                onClick={() => ctx.closeModal("editGroup")}
              >
                Cancelar
              </Button>
              <Button
                type="submit"
                className="btn btn-sm btn-primary my-2 mx-2"
              >
                Guardar
              </Button>
            </div>
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  );
}
