import React, { useContext, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import CardContent from "./cardContent";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import imageDefault from "../../assets/img/background/2.jpg";
import { useLocation, useNavigate } from "react-router-dom";
import { apiUrls } from "../../api/apiConfig";
import { AuthContext } from "../../context/authcontext";
import { postWithAuthMultiPart } from "../../api/api";
import AutoDismissAlert from "../../components/autoDismiss";

const EditCardPicModal = ({ onClose, card }) => {
  const [showAlertHeader, setShowAlertHeader] = useState(false);
  const [message, setMessage] = useState("Esto es un mensaje de prueba");
  const [alertType, setAlertType] = useState("success");
  const defaultSrc = imageDefault;
  const [image, setImage] = useState(defaultSrc);
  const [fileSend, setFileSend] = useState(null);
  const [cropData, setCropData] = useState(null);
  const [croppedImage, setCroppedImage] = useState(false);
  const navigate = useNavigate();
  const cropperRef = useRef(null);
  const { getLoggedSession } = useContext(AuthContext);
  const fileInputRef = useRef(null);
  const IMAGE_SIZE_ALLOWED = process.env.REACT_IMAGE_SIZE_ALLOWED;

  const onChange = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };

  const getCropData = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      setCropData(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
      const canvas = cropperRef.current?.cropper.getCroppedCanvas();
      if (canvas) {
        canvas.toBlob((blob) => {
          if (blob) {
            const file = new File([blob], "cropped-image.jpg", {
              type: "image/jpeg",
            });
            setFileSend(file);
          }
        }, "image/jpeg");
      }
      setCroppedImage(true);
    }
  };

  const handleSave = async (e) => {
    const userSession = getLoggedSession();
    // Validar el tamaño de la imagen
    if (fileSend && fileSend.size > IMAGE_SIZE_ALLOWED) {
      setAlertType("danger");
      setMessage("La imagen es demasiado grande. Debe ser menor de 3 MB.");
      setShowAlertHeader(true);
      return;
    }

    const formData = new FormData();
    formData.append("card_pic", fileSend, "admin.png");
    try {
      const response = await postWithAuthMultiPart(
        userSession.type_user === "owner"
          ? apiUrls.owners.cardsPatchPic(card.id)
          : apiUrls.cards.patchPic(card.id),
        formData,
        userSession
      );

      if (response.status === "success") {
        onClose();
      } else if (response.status === "error") {
        setAlertType("danger");
        setMessage(response.detail);
        setShowAlertHeader(true);
      } else {
        setAlertType("danger");
        console.error("Error creating card admin:", response);
        setMessage("Error inesperado");
        setShowAlertHeader(true);
      }
    } catch (error) {
      console.error("Error creating card admin:", error);
      setAlertType("danger");
      setMessage("Debes rellenar todos los campos");
      setShowAlertHeader(true);
    }
  };

  return (
    <Modal show={true} onHide={onClose} size="xl" centered>
      <Modal.Header closeButton>
        <Modal.Title>Edita la imagen de la card</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <button
            type="button"
            onClick={() => fileInputRef.current.click()}
            className="mb-4 btn btn-sm btn-neutral"
          >
            Subir nueva foto de perfil
          </button>
          <div className="d-flex align-items-center">
            <input
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              ref={fileInputRef} // Ref para el input de tipo archivo
              onChange={onChange}
            />

            <Cropper
              style={{ height: 400, width: "100%" }}
              aspectRatio={9 / 16}
              src={image}
              ref={cropperRef}
              guides={true}
              background={false}
              responsive={true}
              preview=".img-preview"
            />
          </div>
          <button
            type="button"
            className="mt-3 btn btn-sm px-3 btn-primary d-flex align-items-center"
            onClick={getCropData}
          >
            <i className="bi bi-plus-square-dotted me-2"></i>
            <span>Establecer recorte</span>
          </button>
          {cropData ? (
            <>
              <div className="d-flex align-items-center">
                <span className="mt-3">
                  Esta imagen aparecerá como imagen de portada de su tarjeta
                  corporativa
                </span>
              </div>
              <img
                className="fixed-height-cropper mt-4"
                alt="..."
                src={cropData}
              />
            </>
          ) : (
            <div className="mt-3 alert alert-warning" role="alert">
              <div className="d-flex align-items-center mr-3">
                <span className="ml-5">
                  Recorta una imagen compatible con los dispositivos móviles
                  antes de continuar
                </span>
              </div>
            </div>
          )}
        </div>
        {showAlertHeader && (
          <div className="mt-3">
            {/* Contenido para cuando isOkSaveConfig o isOkSavePassword son verdaderos */}
            <AutoDismissAlert
              message={message}
              alertType={alertType}
              onClose={() => setShowAlertHeader(false)}
            />
            {/* Más contenido para cuando isOkSaveConfig o isOkSavePassword son verdaderos */}
          </div>
        )}
        <div className="d-flex mb-4 flex-row-reverse">
          <button
            type="button"
            onClick={onClose}
            className="btn btn-sm btn-neutral my-2 mx-2"
          >
            Cancelar
          </button>
          <button
            type="submit"
            disabled={!croppedImage}
            onClick={handleSave}
            className="btn btn-sm btn-primary my-2 mx-2"
          >
            Guardar
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EditCardPicModal;
