import React, { useState, useEffect } from "react";
import AutoDismissAlert from "../../components/autoDismiss";
import { Header } from "../components/header";
import { MetricsTable } from "../components/table";
import { MostViewed } from "../components/mostViewed";
import { CountByGroup } from "../components/count";
import { useAuth } from "../../context/authcontext";
import { getWithAuth } from "../../api/api";
import { apiUrls } from "../../api/apiConfig";
import { useMetrics } from "../../context/metricsContext";

export function Metrics() {
  const { getLoggedSession } = useAuth();
  const ctx = useMetrics();
  const userSession = getLoggedSession();
  const [metrics, setMetrics] = useState({ count: null, results: [] });
  const [isLoading, setIsLoading] = useState(true);
  const [query, setQuery] = useState({
    group: "all",
    date: null,
    pagination: null,
  });

  useEffect(() => {
    const fetchData = async () => {
      const url = `${apiUrls.metrics.list}${setQueryString()}`;
      try {
        const data = await getWithAuth(url, userSession);
        setMetrics({ ...data });
      } catch (error) {
        ctx.openAlertHeader({
          message: "Ha ocurrido un error conectando con el servidor",
          type: "danger",
        });
      }
      setIsLoading(false);
    };
    fetchData();
  }, [query]);

  const setQueryString = () => {
    const queryArray = [
      query.group === "all" ? "" : `group_id=${query.group}`,
      query.date ? `${query.date}` : "",
      query.pagination ? `${query.pagination}` : "",
    ].filter((q) => q !== "");
    return queryArray.length > 0 ? `?${queryArray.join("&")}` : "";
  };

  return (
    <>
      {ctx.showAlertHeader && (
        <div>
          <AutoDismissAlert
            message={ctx.alert.message}
            alertType={ctx.alert.type}
            onClose={ctx.closeAlertHeader}
          />
        </div>
      )}
      <Header setQuery={setQuery} />
      <MetricsTable
        count={metrics.count}
        metrics={metrics.results}
        isLoading={isLoading}
        setQuery={setQuery}
      />
      <div className="container-fluid pr-3 mt-3 d-flex flex-column flex-xl-row gap-3">
        <MostViewed />
        <CountByGroup />
      </div>
    </>
  );
}
