import React, { useState, useEffect } from "react";
import AutoDismissAlert from "../../components/autoDismiss";
import { useAuth } from "../../context/authcontext";
import { getWithAuth } from "../../api/api";
import { apiUrls } from "../../api/apiConfig";
import { useOrgs } from "../../context/orgsContext";
import { OrgCard } from "../components/org";
import { EditOrgModal } from "../components/editOrgModal";
import { ListAdminsModal } from "../components/listAdminsModal";
import { ListGroupsModal } from "../components/listGroupsModal";
import { ListCardsModal } from "../components/listCardsModal";
import { Loading } from "../../components/loading";
import { Paginator } from "../../components/pagination";
import DeleteModal from "../../components/deleteModal";
import { NewCardModal } from "../components/newCardModal";
import { NewSuperadminModal } from "../components/newSuperadminModal";

export function Organizations() {
  const { getLoggedSession } = useAuth();
  const ctx = useOrgs();
  const userSession = getLoggedSession();
  const [orgs, setOrgs] = useState({ count: null, results: [] });
  const [isLoading, setIsLoading] = useState(true);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 12;

  useEffect(() => {
    const fetchData = async () => {
      const url = `${apiUrls.owners.orgsList}?page=${currentPage}&per_page=${pageSize}`;
      try {
        const response = await getWithAuth(url, userSession);
        setOrgs({ ...response });
      } catch (error) {
        ctx.openAlertHeader({
          message: "Ha ocurrido un error conectando con el servidor",
          type: "danger",
        });
      }
      setIsLoading(false);
    };
    fetchData();
  }, [ctx.apiSuccess, currentPage]);

  const openModal = (modal) => ctx.openModal(modal);
  const closeModal = (modal) => ctx.closeModal(modal);
  const onPageChange = (page) => setCurrentPage(page);

  if (isLoading) return <Loading />;

  return (
    <div className="container-fluid mb-4">
      {/***
       * Alert
       ***/}
      {ctx.showAlertHeader && (
        <div className="mb-5">
          <AutoDismissAlert
            message={ctx.alert.message}
            alertType={ctx.alert.type}
            onClose={ctx.closeAlertHeader}
          />
        </div>
      )}
      {/***
       * Modals
       ***/}
      {ctx.showModal.listCards && (
        <ListCardsModal org={selectedOrg} closeModal={closeModal} />
      )}
      {ctx.showModal.listGroups && (
        <ListGroupsModal org={selectedOrg} closeModal={closeModal} />
      )}
      {ctx.showModal.listAdmins && (
        <ListAdminsModal org={selectedOrg} closeModal={closeModal} />
      )}
      {ctx.showModal.newCard && (
        <NewCardModal org={selectedOrg} closeModal={closeModal} />
      )}
      {ctx.showModal.newSuperadmin && (
        <NewSuperadminModal org={selectedOrg} closeModal={closeModal} />
      )}
      {ctx.showModal.editOrg && (
        <EditOrgModal org={selectedOrg} closeModal={closeModal} />
      )}

      {ctx.showModal.deleteOrg && (
        <DeleteModal
          title={"Eliminar organización"}
          onClose={() => {
            closeModal("deleteOrg");
            ctx.changeApiSuccess();
          }}
          message1={`¿Estás seguro de eliminar la organización ${selectedOrg.name}?`}
          message2="¡Cuidado, se borrarán todos los datos que referencien a esta organización!"
          apiUrl={apiUrls.owners.orgsDelete(selectedOrg.id)}
        />
      )}
      {/***
       * Content
       ***/}
      <div className="vstack gap-6">
        <div className="row g-6">
          {orgs.results.map((org) => (
            <div key={org.id} className="col-xl-3 col-sm-6">
              <OrgCard
                org={org}
                openModal={openModal}
                selectOrg={setSelectedOrg}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="w-100 mt-4 d-flex justifiy-content-center align-items-center">
        <Paginator
          currentPage={currentPage}
          totalPages={Math.max(1, Math.floor(orgs.count / pageSize))}
          onPageChange={onPageChange}
        />
      </div>
    </div>
  );
}
