import React, { useContext, useState } from "react";
import logo from "../../assets/img/logo_white.png";
import logo_movil from "../../assets/img/logo.png";
import { useAuth } from "../../context/authcontext";
import { useCommon } from "../../context/commonContext";
import { apiUrls } from "../../api/apiConfig";
import { post } from "../../api/api";
import { useLocation, useNavigate } from "react-router-dom";
import AutoDismissAlert from "../../components/autoDismiss";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const commonContext = useCommon();

  const handleForgotPassword = async (e) => {
    e.preventDefault();

    setLoading(true);
    try {
      const response = await post(apiUrls.auth.ForgotPassword, {
        username: email, // Usamos el valor del email como username
      });

      if (response.status === "success") {
        commonContext.setUsernameEmail(email);
        navigate("/reset-password");
      } else if (response.status === "error") {
        commonContext.setAlertTypeLogin("danger");
        commonContext.setMessageLogin(response.detail);
        commonContext.openAlertHeaderLogin();
      }

      // history.push('/dashboard');
    } catch (error) {
      console.error("Error al iniciar sesión:", error);
      commonContext.setAlertTypeLogin("danger");
      commonContext.setMessageLogin(error.response.detail);
      commonContext.openAlertHeaderLogin();
    }
    setLoading(false);
  };

  return (
    <div className="px-5 py-5 p-lg-0 min-h-screen bg-surface-secondary d-flex flex-column justify-content-center">
      <div className="d-flex justify-content-center">
        <div className="col-lg-5 col-xl-4 p-12 p-xl-20 position-fixed start-0 top-0 h-screen overflow-y-hidden bg-primary d-none d-lg-flex flex-column">
          <a className="d-block" href="#">
            <img src={logo} className="h-20" alt="..."></img>
          </a>
          <div className="mt-32 mb-20">
            <h1 className="ls-tight font-bolder display-6 text-white mb-5">
              Gestiona tus tarjetas corporativas
            </h1>
            <p className="text-white text-opacity-80">
              Una única plataforma para gestionar las tarjetas de tu
              organización
            </p>
          </div>
        </div>
        <div className="col-12 col-md-9 col-lg-7 offset-lg-5 border-left-lg min-h-screen d-flex flex-column justify-content-center position-relative">
          <div className="text-center mb-12 d-lg-none">
            <a className="d-inline-block" href="#">
              <img
                src={logo_movil}
                className="heigh-logo-mobile-login"
                alt="..."
              ></img>
            </a>
          </div>
          <div className="py-lg-16 px-lg-20">
            <div className="row">
              <div className="col-lg-10 col-md-9 col-xl-6 mx-auto ms-xl-0">
                {/*  <div className="mt-10 mt-lg-5 mb-6 d-lg-block"><span
                                    className="d-inline-block d-lg-block h1 mb-4 mb-lg-6 me-3">👋</span>
                                    <h1 className="ls-tight font-bolder h2">Hola de Nuevo!</h1>
                                </div> */}
                <div className="mb-8">
                  <h3 className="display-5">🔐</h3>
                  <h1 className="h2 ls-tight font-bolder mt-6">
                    ¿Has olvidado tu contraseña?
                  </h1>
                  <p className="mt-2">
                    Introduzca su email coorporativo para que le enviemos un
                    código de recuperación
                  </p>
                </div>

                {commonContext.showAlertHeaderLogin && (
                  <div className="mb-4">
                    {/* Contenido para cuando isOkSaveConfig o isOkSavePassword son verdaderos */}
                    <AutoDismissAlert
                      message={commonContext.messageLogin}
                      alertType={commonContext.alertTypeLogin}
                      onClose={commonContext.closeAlertHeaderLogin}
                    />
                    {/* Más contenido para cuando isOkSaveConfig o isOkSavePassword son verdaderos */}
                  </div>
                )}
                <form onSubmit={handleForgotPassword}>
                  <div className="mb-5">
                    <label className="form-label" htmlFor="email">
                      Correo electrónico
                    </label>{" "}
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    ></input>
                  </div>

                  <div>
                    <button
                      type="submit"
                      href="#"
                      className="btn btn-primary w-full mt-5"
                    >
                      {loading ? <p>Cargando...</p> : <p>Obtener código</p>}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
