import { Card, ButtonGroup, Button } from "react-bootstrap";
import imgOrg from "../../assets/img/background/3.jpg";

export const OrgCard = ({ org, openModal, selectOrg }) => {
  const orgImg = org.config?.logo_url ?? imgOrg;
  return (
    <Card>
      <Card.Img className="mt-8" variant="top" src={orgImg} />
      <Card.Body>
        <Card.Title>{org.name}</Card.Title>
        <Card.Text>{org.domains.join(" - ")}</Card.Text>
      </Card.Body>
      <Card.Footer>
        <ButtonGroup className="mx-7 my-3 d-flex justify-content-center">
          <Button
            onClick={() => {
              openModal("listCards");
              selectOrg(org);
            }}
            title="Ver tarjetas"
          >
            <i className="bi bi-card-text"></i>
          </Button>
          <Button
            onClick={() => {
              openModal("listGroups");
              selectOrg(org);
            }}
            title="Ver grupos"
          >
            <i className="bi bi-people-fill"></i>
          </Button>
          <Button
            onClick={() => {
              openModal("listAdmins");
              selectOrg(org);
            }}
            title="Ver administradores"
          >
            <i className="bi bi-person-fill"></i>
          </Button>
        </ButtonGroup>
        <ButtonGroup className="mx-7 my-3 d-flex justify-content-center">
          <Button
            onClick={() => {
              openModal("newCard");
              selectOrg(org);
            }}
            title="Crear tarjeta"
          >
            <i className="bi bi-plus-circle"></i>
          </Button>
          <Button
            onClick={() => {
              openModal("newSuperadmin");
              selectOrg(org);
            }}
            title="Crear superadmin"
          >
            <i className="bi bi-person-plus-fill"></i>
          </Button>
          <Button
            onClick={() => {
              openModal("editOrg");
              selectOrg(org);
            }}
            title="Editar organización"
          >
            <i className="bi bi-pencil"></i>
          </Button>
          <Button
            onClick={() => {
              openModal("deleteOrg");
              selectOrg(org);
            }}
            title="Borrar organización"
          >
            <i className="bi bi-x-lg p-1 rounded fw-bold"></i>
          </Button>
        </ButtonGroup>
      </Card.Footer>
    </Card>
  );
};
