import React, { useContext, useEffect, useState } from "react";
import UserProfile from "../../components/userProfile";
import { apiUrls } from "../../api/apiConfig";
import { AuthContext } from "../../context/authcontext";
import { getWithAuth } from "../../api/api";
import { useSetting } from "../../context/settingContext";
import { useCommon } from "../../context/commonContext";
import AutoDismissAlert from "../../components/autoDismiss";
import UserSetting from "../../components/userSetting";
import { Loading } from "../../components/loading";

const Profile = () => {
  const { getLoggedSession } = useContext(AuthContext);
  const settingContext = useSetting();
  const commonContext = useCommon();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const userSession = getLoggedSession();
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const data = await getWithAuth(apiUrls.users.profileGet, userSession);
        settingContext.setCurrentUser(data.result);
      } catch (error) {
        console.error("Error fetching user data:", error);
        settingContext.setMessage(
          "Ha ocurrido un error conectando con base de datos"
        );
        settingContext.setAlertType("danger");
        settingContext.openAlertHeader();
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [settingContext.refreshUser]);

  return (
    <>
      <div className="container-fluid max-w-screen-md vstack gap-6">
        {settingContext.showAlertHeader && (
          <div className="mb-4">
            {/* Contenido para cuando isOkSaveConfig o isOkSavePassword son verdaderos */}
            <AutoDismissAlert
              message={settingContext.message}
              alertType={settingContext.alertType}
              onClose={settingContext.closeAlertHeader}
            />
            {/* Más contenido para cuando isOkSaveConfig o isOkSavePassword son verdaderos */}
          </div>
        )}
        {commonContext.showAlertHeaderLogin && (
          <div className="mb-4">
            {/* Contenido para cuando isOkSaveConfig o isOkSavePassword son verdaderos */}
            <AutoDismissAlert
              message={commonContext.messageLogin}
              alertType={commonContext.alertTypeLogin}
              onClose={commonContext.closeAlertHeaderLogin}
            />
            {/* Más contenido para cuando isOkSaveConfig o isOkSavePassword son verdaderos */}
          </div>
        )}
        {isLoading ? (
          // Mostrar el indicador de carga mientras se carga la API.
          <Loading />
        ) : (
          settingContext.currentUser && <UserSetting />
        )}
      </div>
    </>
  );
};

export default Profile;
