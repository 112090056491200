import React from "react";
import { useAuth } from "../../context/authcontext";

function CardStateIcons({ card }) {
  return (
    <div>
      <span className="h6 text-sm font-semibold">
        {card.validation.status === "accepted" && (
          <>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
            >
              <g clipPath="url(#clip0_459_26848)">
                <path
                  d="M15.75 8.5C15.75 12.7802 12.2802 16.25 8 16.25C3.71978 16.25 0.25 12.7802 0.25 8.5C0.25 4.21978 3.71978 0.75 8 0.75C12.2802 0.75 15.75 4.21978 15.75 8.5ZM7.10356 12.6036L12.8536 6.85356C13.0488 6.65831 13.0488 6.34172 12.8536 6.14647L12.1465 5.43937C11.9512 5.24409 11.6346 5.24409 11.4393 5.43937L6.75 10.1287L4.56066 7.93934C4.36541 7.74409 4.04881 7.74409 3.85353 7.93934L3.14644 8.64644C2.95119 8.84169 2.95119 9.15828 3.14644 9.35353L6.39644 12.6035C6.59172 12.7988 6.90828 12.7988 7.10356 12.6036Z"
                  fill="#00A3FF"
                />
              </g>
              <defs>
                <clipPath id="clip0_459_26848">
                  <rect
                    width="16"
                    height="16"
                    fill="white"
                    transform="translate(0 0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
            <span className="me-2  m-2 text-accepted font-semibold">
              Aceptada
            </span>
          </>
        )}
        {card.validation.status === "pending" && (
          <>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className=" text-pending bi bi-clock-fill"
              viewBox="0 0 16 16"
            >
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
            </svg>
            <span className="me-2 m-2 text-pending font-semibold">
              Pendiente
            </span>
          </>
        )}
        {card.validation.status === "rejected" && (
          <>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="text-rejected bi bi-x-circle-fill"
              viewBox="0 0 16 16"
            >
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
            </svg>
            <span className="me-2  m-2 text-rejected font-semibold">
              Rechazada
            </span>
          </>
        )}
      </span>
    </div>
  );
}

export function UserCard({ card, openModal }) {
  const { getLoggedSession } = useAuth();
  const userSession = getLoggedSession();

  return (
    <div className="col-xl-3 col-sm-6">
      <div className="card">
        <div className="card-header border-0">
          <div className="d-flex justify-content-between align-items-center">
            <CardStateIcons card={card} />
            <div className="text-end mx-n2 d-flex align-items-center">
              <div className="dropdown">
                <span
                  className="text-muted"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="bi bi-three-dots"></i>
                </span>
                <div className="dropdown-menu">
                  <a
                    href="#" // eslint-disable-line
                    onClick={() => openModal("activate", card)}
                    className="dropdown-item"
                  >
                    Reactivar tarjeta{" "}
                  </a>
                  <a
                    href="#" // eslint-disable-line
                    onClick={() => openModal("delete", card)}
                    className="dropdown-item"
                  >
                    Eliminar{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-body card-body-card text-center">
          <div className="rounded">
            <img
              alt="..."
              src={card.config.card_pic_url}
              className="fixed-height-image"
            ></img>
          </div>
          <p className="h5 d-block mt-4">
            {card.employee.name ?? "No definido"}
          </p>
          <p className="h6 d-block mt-0">
            {card.employee.lastname ?? "No definido"}
          </p>
          <span className="d-block text-sm text-muted mt-2">
            {card.employee.job ?? "No definido"}
          </span>
          <span className="d-block text-sm text-muted">
            {card.employee.email ?? "No definido"}
          </span>
          {userSession?.type_user === "owner" && (
            <>
              <span className="d-block text-sm text-muted">
                Views: <span className="ml-2">{card.metrics.views}</span>
              </span>
              <span className="d-block text-sm text-muted">
                Conversion:{" "}
                <span className="ml-2">{card.metrics.conversion}</span>
              </span>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
