import React, { useState } from "react";
import { Form, Button, Card } from "react-bootstrap";
import { useOrgs } from "../../context/orgsContext";
import { postWithAuthMultiPart } from "../../api/api";
import { apiUrls } from "../../api/apiConfig";
import { useAuth } from "../../context/authcontext";
import AutoDismissAlert from "../../components/autoDismiss";
import { FileUploader } from "../components/fileUploader";
import defaultImage from "../../assets/img/background/2.jpg";

export function NewOrg() {
  const { getLoggedSession } = useAuth();
  const userSession = getLoggedSession();
  const ctx = useOrgs();
  const [image, setImage] = useState(defaultImage);
  const [logo, setLogo] = useState(null);
  const [newOrg, setNewOrg] = useState({
    name: "",
    main_color: "",
    secondary_color: "",
    domains: "",
  });

  const handleInputChange = (ev) => {
    const { id, value } = ev.target;
    setNewOrg({ ...newOrg, [id]: value });
  };

  const resetForm = () => {
    setNewOrg({
      name: "",
      main_color: "",
      secondary_color: "",
      domains: "",
    });
  };

  const handleSave = async (ev) => {
    ev.preventDefault();
    const newOrgData = {
      name: newOrg.name,
      config: {
        main_color: newOrg.main_color,
        secondary_color: newOrg.secondary_color,
      },
      domains: newOrg.domains.split(",").map((d) => d.trim()),
    };
    const formData = new FormData();
    formData.append("data", JSON.stringify(newOrgData));
    formData.append("logo", logo, "logo.png");
    try {
      await postWithAuthMultiPart(
        apiUrls.owners.orgsPost,
        formData,
        userSession
      );
      ctx.openAlertHeader({
        message: "Organización creada exitosamente",
        type: "success",
      });
    } catch (error) {
      ctx.openAlertHeader({
        message: "Ha ocurrido un error conectando con el servidor",
        type: "danger",
      });
    } finally {
      resetForm();
    }
  };

  return (
    <div className="container-fluid max-w-screen-md vstack gap-5">
      {/***
       * Alert
       ***/}
      {ctx.showAlertHeader && (
        <div className="mb-5">
          <AutoDismissAlert
            message={ctx.alert.message}
            alertType={ctx.alert.type}
            onClose={ctx.closeAlertHeader}
          />
        </div>
      )}
      <Card>
        <Card.Body>
          <Card.Title>Nueva Organización</Card.Title>
          <Form onSubmit={handleSave}>
            <Form.Group className="mb-4" controlId="name">
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                value={newOrg.name}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group className="mb-4" controlId="main_color">
              <Form.Label>Color principal</Form.Label>
              <Form.Control
                type="text"
                value={newOrg.main_color}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group className="mb-4" controlId="secondary_color">
              <Form.Label>Color secundario</Form.Label>
              <Form.Control
                type="text"
                value={newOrg.secondary_color}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group className="mb-4" controlId="domains">
              <Form.Label>Dominios aceptados</Form.Label>
              <Form.Control
                type="text"
                value={newOrg.domains}
                onChange={handleInputChange}
              />
              <Form.Text className="muted">
                Separar cada dominio con coma
              </Form.Text>
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label>Logo</Form.Label>
              <FileUploader
                image={image}
                setImage={setImage}
                setLogo={setLogo}
              />
            </Form.Group>
            <div className="d-flex mb-4 flex-row-reverse">
              <Button
                className="btn btn-sm btn-neutral my-2 mx-2"
                onClick={resetForm}
              >
                Cancelar
              </Button>
              <Button
                type="submit"
                className="btn btn-sm btn-primary my-2 mx-2"
              >
                Guardar
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
}
